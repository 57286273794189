import { Injectable } from '@angular/core';
import * as firebase from 'firebase';
import { IfStmt } from '@angular/compiler';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  activeTab: any = 'dashboard';
  role: string;

  currentUser: any = {};

  constructor(public router: Router) {
    
    if (this.router.url == '/dashboard') {
      this.activeTab = 'dashboard';
    }
    else if (this.router.url == '/settings') {
      this.activeTab = 'settings';
    }
    else if (this.router.url == '/customers') {
      this.activeTab = 'customers';
    }
    else if (this.router.url == '/alerts') {
      this.activeTab = 'alerts';
    }
    else {
      this.activeTab = 'accounts';
    }

    if (localStorage.getItem("uid")) {
      if (localStorage.getItem("userObj")) {
        this.currentUser = JSON.parse(localStorage.getItem("userObj"));
      }
      firebase.database().ref(`/users/${localStorage.getItem("currUserKey")}`).once("value")
        .then(snapshot => {
          this.currentUser = snapshot.val();

          localStorage.setItem("userObj", JSON.stringify(this.currentUser));
        })
    }
  }


}

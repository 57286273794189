import { Component, OnInit, ElementRef, ViewChild, NgZone } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as firebase from 'firebase';



@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {

  public loginForm: FormGroup;
  public provider: any;
  public loading = false;
  public uid: any;
  public email: any;
  public displayName; any;
  public photoURL: any;
  public alreadyExists: any = false;


  constructor(private _fb: FormBuilder, public router: Router) {

    var x = document.getElementById("myNav");
    x.style.display = "none";
    this.provider = new firebase.auth.GoogleAuthProvider();

    if (localStorage.getItem("uid")) {
      router.navigate(['/dashboard']);
    }
  }

  ngOnInit() {
    this.loginForm = this._fb.group({
      email: ['', Validators.compose([
        Validators.required
      ])],
      password: ['', Validators.compose([
        Validators.required
      ])],
    });
  }

  login() {

    this.loading = true;
    var self = this;
    firebase.auth().signInWithEmailAndPassword(self.loginForm.get("email").value, self.loginForm.get("password").value)
      .then(
        (user) => {
          if (user) {

            // User is signed in.
            localStorage.setItem("uid", user.user.uid);
            self.getuserData();
          }
        }).catch(function (error) {
          self.loading = false;
          var errorCode = error.code;
          var errorMessage = error.message;
          alert(errorMessage);

        });
  }

  getuserData() {

    var self = this;

    firebase.database().ref().child('users')
      .orderByChild("uid")
      .equalTo(firebase.auth().currentUser.uid)
      .once('value', function (snapshot) {
        var users = snapshot.val();
        for (var key in users) {
          var user = users[key];
          if (user.uid == firebase.auth().currentUser.uid) {

            self.loading = false;
            localStorage.setItem("oneTimeReload", "true");
            localStorage.setItem("displayName", user.displayName);
            localStorage.setItem("deviceId", user.deviceId);
            localStorage.setItem("currUserKey", key);
            localStorage.setItem("userState", user.state);

            if (user.postalCode) {
              localStorage.setItem("postalCode", user.postalCode);
            }

            self.receiveTankSettings();
          }
        }
      });
  }


  receiveTankSettings() {


    var self = this;
    self.loading = true;

    firebase.database().ref().child('tankSettings')
      .orderByChild("uid")
      .equalTo(localStorage.getItem("uid"))
      .once('value', function (snapshot) {
        var tankSettings = snapshot.val();

        var defaultTank: any = "", found = false;


        for (var key in tankSettings) {
          var tankSetting = tankSettings[key];

          if (!defaultTank) {
            defaultTank = tankSetting;
            defaultTank.key = key;
          }

          if (tankSetting.deviceId === localStorage.getItem("deviceId")) {

            //Update Here Directly to Local Store..
            localStorage.setItem("estimatedAvgUsage", tankSetting.estimatedAvgUsage);
            localStorage.setItem("outletHeight", tankSetting.outletHeight);
            localStorage.setItem("overflowHeight", tankSetting.overflowHeight);
            localStorage.setItem("tankQuantity", tankSetting.tankQuantity);
            localStorage.setItem("tankDiameter", tankSetting.tankDiameter);
            localStorage.setItem("tankArea", tankSetting.tankArea);
            localStorage.setItem("maximumHeight", tankSetting.maximumHeight);
            localStorage.setItem("maxVolume", tankSetting.maxVolume);
            localStorage.setItem("avgEst", tankSetting.avgEst);

            found = true;

            self.router.navigate(['/dashboard']);

            /* if(localStorage.getItem("postalCode")) {
              self.router.navigate(['/dashboard']);
            } else {
              self.router.navigate(['/completeProfile']);
            } */
            self.loading = false;
            return;
          }
        }

        if (!found && defaultTank) {
          localStorage.setItem("estimatedAvgUsage", defaultTank.estimatedAvgUsage);
          localStorage.setItem("outletHeight", defaultTank.outletHeight);
          localStorage.setItem("overflowHeight", defaultTank.overflowHeight);
          localStorage.setItem("tankQuantity", defaultTank.tankQuantity);
          localStorage.setItem("tankDiameter", defaultTank.tankDiameter);
          localStorage.setItem("tankArea", defaultTank.tankArea);
          localStorage.setItem("maximumHeight", defaultTank.maximumHeight);
          localStorage.setItem("maxVolume", defaultTank.maxVolume);
          localStorage.setItem("avgEst", defaultTank.avgEst);

          defaultTank.deviceId = localStorage.getItem("deviceId");
          defaultTank.isDefault = true;

          firebase.database().ref(`/tankSettings/${defaultTank.key}`).set(defaultTank);
        }

        self.router.navigate(['/dashboard']);

        /* if(localStorage.getItem("postalCode")) {
          self.router.navigate(['/settings']);
        } else {
          self.router.navigate(['/completeProfile']);
        } */

        self.loading = false;
      });
  }


  loginwithGoogle() {
    var self = this;
    self.loading = true;
    firebase.auth().signInWithPopup(this.provider).then(function (result) {
      // This gives you a Google Access Token. You can use it to access the Google API.
      //var token = result.credential.accessToken;
      // The signed-in user info.
      var user = result.user;

      self.uid = user.uid;
      self.email = user.email;
      self.displayName = user.displayName;
      self.photoURL = user.photoURL;

      localStorage.setItem("displayName", user.displayName);
      localStorage.setItem("uid", self.uid);

      self.checkAlreadyExists();
      //self.saveDatatoUserTable(user.uid, user.email,  user.displayName , user.photoURL);
    }).catch(function (error) {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      // The email of the user's account used.
      var email = error.email;
      // The firebase.auth.AuthCredential type that was used.
      var credential = error.credential;
      self.loading = false;


    });
  }


  checkAlreadyExists() {

    var self = this;

    firebase.database().ref().child('users')
      .once('value', function (snapshot) {
        var users = snapshot.val();
        for (var key in users) {
          var user = users[key];
          if (user.email == self.email) {
            self.alreadyExists = true;
            localStorage.setItem("deviceId", user.deviceId);
            localStorage.setItem("currUserKey", key);

          }
        }

        if (self.alreadyExists) {
          localStorage.setItem("oneTimeReload", "true");
          self.receiveTankSettings();
        } else {
          self.saveDatatoUserTable();
        }
      });
  }

  saveDatatoUserTable() {

    var self = this;
    var postData: any = {
      uid: self.uid,
      email: self.email.toLowerCase(),
      displayName: self.displayName,
      photoURL: self.photoURL,
      timestamp: Number(new Date()),
    };

    var newPostKey = firebase.database().ref().child('users').push().key;

    // Write the new post's data simultaneously in the posts list and the user's post list.
    var updates = {};
    updates['/users/' + newPostKey] = postData;
    firebase.database().ref().update(updates).then(() => {
      self.loading = false;
      self.router.navigate(['/completeProfile']);
      localStorage.setItem("oneTimeReload", "true");
      localStorage.setItem("displayName", self.displayName);
      localStorage.setItem("currUserKey", newPostKey);
    });
  }





}

import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';

import * as firebase from 'firebase';

import * as moment from 'moment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.css']
})
export class CustomersComponent implements OnInit {

  pendingCustomers: Array<any> = [];

  customers: Array<any> = [];

  allCustomers: Array<any> = [];

  currentUserIndex: number = -1;

  selectedCustomer: string = "";

  query: string = "";

  currentSort: string = "";
  sortOrder: string = "asc";

  constructor(public service: DataService, public router: Router) {
    this.service.activeTab = 'customers';

    if (localStorage.getItem("oneTimeReload") == "true") {
      localStorage.removeItem("oneTimeReload")
      location.reload();
    }
  }

  ngOnInit() {
    this.getCustomers();

    /* var func = firebase.functions().httpsCallable("getAllUserTanks");

    func({})
      .then(res => {
        
      })
      .catch(err => {
        
      }); */
  }

  showDashboard() {
    if (!this.selectedCustomer) {
      return;
    }
    this.router.navigate([`/dashboard/${this.selectedCustomer}`]);
  }

  resetPassword() {
    if (!this.selectedCustomer) {
      return;
    }

    var func = firebase.functions().httpsCallable("changePassword");

    func({ uid: this.selectedCustomer, password: "password1" })
      .then((res) => {
        if (res.data.status == 200) {
          alert("Password updated!");
        } else {
          alert("An issue occured while updating password!");
        }
      })
      .catch(err => {
        alert("An issue occured while updating password!");
      })
  }

  showSettings() {
    if (!this.selectedCustomer) {
      return;
    }
    this.router.navigate([`/settings/${this.selectedCustomer}`]);
  }

  getCustomers() {
    if (this.service.currentUser.isSuperAdmin) {
      firebase.database().ref(`/users`)
        .once("value")
        .then(snapshot => {
          var data = snapshot.val();

          for (var key in data) {
            var temp = data[key];

            temp.key = key;

            if (!temp.isManager && !temp.isSuperAdmin && temp.uid) {
              this.allCustomers.push(temp);
            }
          }

          this.customers = this.allCustomers;

          //this.customers = this.allCustomers.slice(0, 20);

          this.getUserData();
        });
    } else {
      firebase.database().ref(`/users`)
        .orderByChild("managerUid")
        .equalTo(this.service.currentUser.uid)
        .once("value")
        .then(snapshot => {
          var data = snapshot.val();

          for (var key in data) {
            var temp = data[key];

            temp.key = key;

            if (temp.managerApproved) {
              this.allCustomers.push(temp);
            } else {
              this.pendingCustomers.push(temp);
            }
          }

          //this.allCustomers = this.customers;

          //this.customers = this.allCustomers.slice(0, 20);
          this.customers = this.allCustomers;

          this.getUserData();
        });
    }
  }

  onScrollDown(ev) {
    console.log("scrolled down!!", ev);

    /* this.customers = this.customers.concat(this.allCustomers.slice(this.customers.length - 1, this.customers.length + 20));

    this.getUserData(); */
  }

  searchData() {
    this.customers = this.allCustomers.filter(x => x.displayName.toLocaleLowerCase().indexOf(this.query.toLocaleLowerCase()) >= 0
      || x.email.toLocaleLowerCase().indexOf(this.query.toLocaleLowerCase()) >= 0);
  }

  rejectCustomer(index) {
    if (!confirm("Are you sure want to reject this customer?")) {
      return;
    }

    var user = this.pendingCustomers[index];

    this.pendingCustomers.splice(index, 1);

    firebase.database().ref(`/users/${user.key}/managerCode`).remove();
    firebase.database().ref(`/users/${user.key}/managerUid`).remove();
    firebase.database().ref(`/users/${user.key}/managerName`).remove();
    firebase.database().ref(`/users/${user.key}/managerApproved`).remove();
  }

  approveCustomer() {
    var user = this.pendingCustomers[this.currentUserIndex];

    this.pendingCustomers.splice(this.currentUserIndex, 1);

    user.managerApproved = true;

    firebase.database().ref(`/users/${user.key}/managerApproved`).set(true);

    location.reload();
  }

  getUserData() {
    for (var i = 0; i < this.customers.length; i++) {
      this.getAllTanks(this.customers[i]);
    }
  }

  getAllTanks(customer) {
    customer.allTanks = [];
    firebase.database().ref("/tankSettings")
      .orderByChild("uid")
      .equalTo(customer.uid)
      .once("value")
      .then(snapshot => {
        var data = snapshot.val();

        for (var key in data) {
          var temp = data[key];
          temp.key = key;
          if (temp.deviceId)
            customer.allTanks.push(temp);
        }
        if (customer.allTanks.length) {
          customer.allTanks.forEach((tank) => {
            this.getDeviceConfiguration(tank);
          })

          this.getAllData(customer);
        } else {
          var index = this.customers.findIndex(x => x.uid === customer.uid);

          this.customers.splice(index, 1);

          var index = this.allCustomers.findIndex(x => x.uid === customer.uid);

          //this.allCustomers.splice(index, 1);
        }
      });
  }

  /* getAllTanks(userIndex) {
    this.customers[userIndex].allTanks = [];
    
    firebase.database().ref("/tankSettings")
      .orderByChild("uid")
      .equalTo(this.customers[userIndex].uid)
      .once("value")
      .then(snapshot => {
        var data = snapshot.val();
        for (var key in data) {
          var temp = data[key];
          temp.key = key;
          this.customers[userIndex].allTanks.push(temp);
        }
        if (this.customers[userIndex].allTanks.length) {
          this.customers[userIndex].allTanks.forEach((tank) => {
            this.getDeviceConfiguration(tank);
          })
        } else {

        }

        this.getAllData(this.customers[userIndex]);

      });
  } */

  getDeviceConfiguration(tank) {
    tank.deviceState = {};
    firebase.database().ref(`/devices/${tank.deviceId}/state`)
      .once('value', (snapshot) => {
        var data = snapshot.val();

        tank.deviceConfig = {};

        if (!data) {
          return;
        }

        tank.deviceConfig = data;

        if (data.batVoltage) {
          if (data.batVoltage < 3300) {
            tank.deviceState = data;
          }
        }

      });
  }

  getAllData(user) {
    var promises = [];

    user.allTanks.forEach(tank => {
      var p = this.getReadingOfSpecificDevice(tank.deviceId)
        .then(async data => {
          tank.deviceData = data.deviceData || [];
          tank.allDeviceData = data.allDeviceData || [];
          await this.calculateCurrentVolumeOfTank(tank);
          await this.waterUsageOfTank(tank);
          return true;
        });
      promises.push(p);
    });

  }

  getReadingOfSpecificDevice(deviceId): Promise<any> {
    return firebase.database().ref().child(`deviceData/${deviceId}`)
      .limitToLast(100)
      .once('value')
      .then((snapshot) => {
        var deviceData = [];
        var allDeviceData = [];
        var userDevice = snapshot.val();

        for (var data in userDevice) {
          var temp = userDevice[data];
          if (temp.data.indexOf("waterLevel") >= 0) {
            continue;
          }
          allDeviceData.push(temp);
          if (deviceData.length > 0) {
            var currentDate = new Date(temp.published_at);
            var lastDate = new Date(deviceData[deviceData.length - 1].published_at);

            if (currentDate.getDate() === lastDate.getDate()) {
              deviceData[deviceData.length - 1] = temp;
            } else {
              deviceData.push(temp);
            }
          } else {
            deviceData.push(temp);
          }
        }

        var allData: any = {
          deviceData: deviceData,
          allDeviceData: allDeviceData
        }

        return allData;
      });
  }


  async calculateCurrentVolumeOfTank(tank) {
    var length = tank.deviceData.length;
    tank.waterStats = {
      dailyUsage: {
        val: 0,
        percent: 0
      }
    };

    if (length < 1) {
      tank.waterStats.waterLevel = 0;
      tank.waterStats.yesterdayWaterLevel = 0;
      tank.waterStats.currentVolume = 0;
      tank.waterStats.waterDays = 0;
      tank.waterStats.percentVolume = 0;
      tank.waterStats.yesterdayVolume = 0;
      return;
    }

    tank.waterStats.waterLevel = tank.deviceData[length - 1].data;
    tank.waterStats.waterLevel = Number(tank.waterStats.waterLevel);
    if (length > 1) {
      tank.waterStats.yesterdayWaterLevel = tank.deviceData[length - 2].data;
      tank.waterStats.yesterdayWaterLevel = Number(tank.waterStats.yesterdayWaterLevel);
    } else {
      tank.waterStats.yesterdayWaterLevel = tank.waterStats.waterLevel;
    }

    //Last-Reading Calculations//

    if (length > 1) {
      var lastReading = tank.deviceData[length - 1].published_at;
      var lastReadingBefore = tank.deviceData[length - 2].published_at;
    } else if (length === 1) {
      var lastReading = tank.deviceData[length - 1].published_at;
      var lastReadingBefore = tank.deviceData[length - 1].published_at;
    } else {
      return;
    }

    //var lastReading = tank.deviceData[length - 1].published_at;

    var mydate = new Date(lastReading);
    var todayDate = new Date(lastReadingBefore);

    tank.waterStats.lastReadingStr = moment(mydate).format("ddd D/MM [at] HH:mm");
    //tank.waterStats.lastReadingMillis = moment(mydate).milliseconds();
    tank.waterStats.lastReadingMillis = Number(mydate);
    if (!tank.lastReadingStr) {
      tank.lastReadingStr = tank.waterStats.lastReadingStr;
    }

    tank.waterStats.dailUsageTodayDate = moment(todayDate).format("ddd D/MM");
    if (!tank.dailUsageTodayDate) {
      tank.dailUsageTodayDate = tank.waterStats.dailUsageTodayDate;

    }

    tank.waterStats.currentVolume = (tank.waterStats.waterLevel * 1000 - tank.outletHeight) * (tank.tankArea);

    tank.waterStats.currentVolume = Math.round(tank.waterStats.currentVolume);



    tank.waterStats.percentVolume = (tank.waterStats.currentVolume / tank.maxVolume) * 100;
    tank.waterStats.percentVolume = Math.round(tank.waterStats.percentVolume);


    tank.waterStats.yesterdayVolume = (tank.waterStats.yesterdayWaterLevel * 1000 - tank.outletHeight) * (tank.tankArea);
    tank.waterStats.dailyUsage.val = tank.waterStats.yesterdayVolume - tank.waterStats.currentVolume;
    tank.waterStats.dailyUsage.val = Math.round(tank.waterStats.dailyUsage.val);


    tank.waterStats.waterDays = tank.waterStats.currentVolume / (tank.waterStats.yesterdayVolume - tank.waterStats.currentVolume);
    tank.waterStats.waterDays = Math.round(tank.waterStats.waterDays);
    tank.waterStats.waterDaysNumber = Math.round(tank.waterStats.waterDays);

    if (tank.waterStats.waterDays > 60) {
      tank.waterStats.waterDays = "60+"
    }

  }

  async waterUsageOfTank(tank) {
    var length = tank.deviceData.length;

    tank.waterUsageArray = [];
    tank.waterUsageDateArray = [];

    tank.waterStats.waterUsageLast14Days = {
      val: 0,
      percent: 0
    };
    tank.waterStats.waterUsageLast7Days = {
      val: 0,
      percent: 0
    };
    tank.waterStats.waterUsageLast30Days = {
      val: 0,
      percent: 0
    };

    var index = 0;
    var nowIndex = 0;
    var tempIndex = 0;
    var currentDateIndex = 0;
    var days = 0;

    for (var i = 0; i < tank.deviceData.length - 1 && index < tank.deviceData.length && tank.waterUsageArray.length < tank.deviceData.length; i++) {
      var waterLevel = 0;
      var yesterdayVolume = 0;
      var currentVolume = 0;
      var yesterdayWaterLevel = 0;
      var dailyUsage = 0;

      var date = new Date();
      var yesterday = new Date(date.getTime());
      yesterday.setDate(date.getDate() - (i + 1));


      var now = new Date(date.getTime());

      now.setDate(date.getDate() - (currentDateIndex + nowIndex));
      var sampleDate = now.getDate() + "/" + (now.getMonth() + 1);

      if (index < tank.deviceData.length - 1) {

        var currentreadingDate = new Date(tank.deviceData[length - (index + 1)].published_at);
        var yesterdayReadingDate = new Date(tank.deviceData[length - (index + 2)].published_at);

        if (now.toLocaleDateString() === currentreadingDate.toLocaleDateString()) {
          if (now.toLocaleDateString() === currentreadingDate.toLocaleDateString() && yesterday.toLocaleDateString() === yesterdayReadingDate.toLocaleDateString()) {
            yesterdayWaterLevel = tank.deviceData[length - (index + 2)].data;
            yesterdayWaterLevel = Number(yesterdayWaterLevel);
            waterLevel = Number(tank.deviceData[length - (index + 1)].data);
            currentVolume = (waterLevel * 1000 - tank.outletHeight) * (tank.tankArea);
            yesterdayVolume = (yesterdayWaterLevel * 1000 - tank.outletHeight) * (tank.tankArea);
            dailyUsage = yesterdayVolume - currentVolume;
            index++;
            nowIndex += tempIndex;
            currentDateIndex++;
            tempIndex = 0;
            tank.waterUsageDateArray.push(sampleDate.toString());
            tank.waterUsageArray.push(dailyUsage);

            if (days < 7 && dailyUsage > 0) {
              tank.waterStats.waterUsageLast7Days.val = tank.waterStats.waterUsageLast7Days.val + dailyUsage;
              tank.waterStats.waterUsageLast14Days.val = tank.waterStats.waterUsageLast14Days.val + dailyUsage;
              tank.waterStats.waterUsageLast30Days.val = tank.waterStats.waterUsageLast30Days.val + dailyUsage;
              days++;
            } else if (days < 14 && dailyUsage > 0) {
              tank.waterStats.waterUsageLast14Days.val = tank.waterStats.waterUsageLast14Days.val + dailyUsage;
              tank.waterStats.waterUsageLast30Days.val = tank.waterStats.waterUsageLast30Days.val + dailyUsage;
              days++;
            } else if (days < 30 && dailyUsage > 0) {
              tank.waterStats.waterUsageLast30Days.val = tank.waterStats.waterUsageLast30Days.val + dailyUsage;
              days++;
            }
          } else {
            tempIndex++;
          }
        } else {
          currentDateIndex++;
        }
      }
    }

    if (days < 1) {
      tank.waterStats.waterUsageLast7Days.val = 0;
      tank.waterStats.waterUsageLast14Days.val = 0;
      tank.waterStats.waterUsageLast30Days.val = 0;
    } else if (days < 7) {
      tank.waterStats.waterUsageLast7Days.val = Math.round(tank.waterStats.waterUsageLast7Days.val / days);
      tank.waterStats.waterUsageLast14Days.val = Math.round(tank.waterStats.waterUsageLast14Days.val / days);
      tank.waterStats.waterUsageLast30Days.val = Math.round(tank.waterStats.waterUsageLast30Days.val / days);
    } else if (days < 14) {
      tank.waterStats.waterUsageLast7Days.val = Math.round(tank.waterStats.waterUsageLast7Days.val / 7);
      tank.waterStats.waterUsageLast14Days.val = Math.round(tank.waterStats.waterUsageLast14Days.val / days);
      tank.waterStats.waterUsageLast30Days.val = Math.round(tank.waterStats.waterUsageLast30Days.val / days);
    } else if (days < 30) {
      tank.waterStats.waterUsageLast7Days.val = Math.round(tank.waterStats.waterUsageLast7Days.val / 7);
      tank.waterStats.waterUsageLast14Days.val = Math.round(tank.waterStats.waterUsageLast14Days.val / 14);
      tank.waterStats.waterUsageLast30Days.val = Math.round(tank.waterStats.waterUsageLast30Days.val / days);
    } else {
      tank.waterStats.waterUsageLast7Days.val = Math.round(tank.waterStats.waterUsageLast7Days.val / 7);
      tank.waterStats.waterUsageLast14Days.val = Math.round(tank.waterStats.waterUsageLast14Days.val / 14);
      tank.waterStats.waterUsageLast30Days.val = Math.round(tank.waterStats.waterUsageLast30Days.val / 30);
    }

    tank.waterStats.waterDays = tank.waterStats.currentVolume / tank.waterStats.waterUsageLast14Days.val;
    tank.waterStats.waterDays = Math.round(tank.waterStats.waterDays);
    tank.waterStats.waterDaysNumber = Math.round(tank.waterStats.waterDays);

    if (tank.waterStats.waterDays > 60) {
      tank.waterStats.waterDays = "60+"
    }

    var max = tank.waterStats.dailyUsage.val;

    if (tank.waterStats.waterUsageLast14Days.val > max) {
      max = tank.waterStats.waterUsageLast14Days.val;
    }

    tank.waterStats.dailyUsage.percent = tank.waterStats.dailyUsage.val / max * 100;
    tank.waterStats.waterUsageLast14Days.percent = tank.waterStats.waterUsageLast14Days.val / max * 100;

  }

  sortByName() {
    this.currentSort = "name";

    if (this.sortOrder === "asc") {
      this.customers.sort((a, b) => (a.displayName as string).localeCompare(b.displayName));
      this.sortOrder = "desc";
    } else {
      this.customers.sort((a, b) => (b.displayName as string).localeCompare(a.displayName));
      this.sortOrder = "asc";
    }
  }

  sortByStats(name) {
    this.currentSort = name;

    if (this.sortOrder === "asc") {
      this.customers.forEach(customer => {
        customer.allTanks.sort((a, b) => b.waterStats[name] - a.waterStats[name]);
      });

      this.customers.sort((a, b) => b.allTanks[0].waterStats[name] - a.allTanks[0].waterStats[name]);
      this.sortOrder = "desc";
    } else {
      this.customers.forEach(customer => {
        customer.allTanks.sort((a, b) => a.waterStats[name] - b.waterStats[name]);
      });

      this.customers.sort((a, b) => a.allTanks[0].waterStats[name] - b.allTanks[0].waterStats[name]);
      this.sortOrder = "asc";
    }
  }

  sortByConfig(name) {
    this.currentSort = name;

    if (this.sortOrder === "asc") {
      this.customers.forEach(customer => {
        customer.allTanks.sort((a, b) => b.deviceConfig[name] - a.deviceConfig[name]);
      });

      this.customers.sort((a, b) => b.allTanks[0].deviceConfig[name] - a.allTanks[0].deviceConfig[name]);
      this.sortOrder = "desc";
    } else {
      this.customers.forEach(customer => {
        customer.allTanks.sort((a, b) => a.deviceConfig[name] - b.deviceConfig[name]);
      });

      this.customers.sort((a, b) => a.allTanks[0].deviceConfig[name] - b.allTanks[0].deviceConfig[name]);
      this.sortOrder = "asc";
    }
  }

}

import { Component, OnInit, ElementRef, ViewChild, NgZone } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as firebase from 'firebase';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  public resetForm: FormGroup;
  public provider : any; 
  public loading = false;
  public email:any;
  


  constructor(private _fb: FormBuilder,public router:Router){

    var x = document.getElementById("myNav"); 
    x.style.display = "none";
    this.provider = new firebase.auth.GoogleAuthProvider();
 
   }

  
  ngOnInit(){
    this.resetForm = this._fb.group({
      email: ['', Validators.compose([
        Validators.required
      ])],
     });
  }
   
    sendEmail() {
      var self = this;
      var auth = firebase.auth();
      auth.sendPasswordResetEmail(self.resetForm.get("email").value)
        .then(
          () => alert("Reset Password Email Sent!!"))
        .catch((error) => alert(error.message))
    }

}

import { Component, OnInit } from '@angular/core';

import * as firebase from 'firebase';
import { Router } from '@angular/router';
import { DataService } from '../data.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  public displayName:any;

  constructor(public router:Router,public service:DataService){ 
    this.displayName = localStorage.getItem("displayName");
  }

  ngOnInit() {
  }

  logout() {
    firebase.auth().signOut();

    localStorage.clear();
    this.router.navigate(['/login']);
  }

}

import { Component, OnInit } from '@angular/core';
import * as firebase from 'firebase';



@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {

  public alertsCount: any = 0;
  public lowWaterCount: any = 2000;
  public dailyUsageCount: any = 1000;
  public batteryCount: any = 0;
  public loading: any = false;
  public alreadyExists: any = false;
  public notiAlertsCbox: any = false;
  public lowWaterCbox: any = false;
  public dailyUsageCbox: any = false;
  public wifiAlertsCbox: any = false;


  constructor() {
    this.receiveNotificationSettings();
  }

  ngOnInit() {
  }

  receiveNotificationSettings() {


    var self = this;
    self.loading = true;

    firebase.database().ref().child('notificationsSettings')
      .once('value', function (snapshot) {
        var notiSettings = snapshot.val();
        for (var key in notiSettings) {
          var notiSetting = notiSettings[key];
          if (notiSetting.uid == localStorage.getItem("uid")) {

            //Update Here Directly..
            self.alertsCount = notiSetting.alertsCount;
            self.dailyUsageCount = notiSetting.dailyUsageCount;
            self.lowWaterCount = notiSetting.lowWaterCount;
            self.batteryCount = notiSetting.batteryCount;
            self.notiAlertsCbox = notiSetting.notiAlertsCbox;
            self.lowWaterCbox = notiSetting.lowWaterCbox;
            self.dailyUsageCbox = notiSetting.dailyUsageCbox;
            self.wifiAlertsCbox = notiSetting.wifiAlertsCbox;
          }
        }
        self.loading = false;
      });
  }

  notiAlerts(event: any) {
    this.notiAlertsCbox = event;
    if (event == true) {
      this.lowWaterCbox = false;
    }

  }

  lowWaterAlerts(event: any) {
    this.lowWaterCbox = event;

    if (event == true) {
      this.notiAlertsCbox = false;
    }

  }

  dailyUsageAlerts(event: any) {
    this.dailyUsageCbox = event;

  }

  wifiAlertsAlerts(event: any) {
    this.wifiAlertsCbox = event;

  }


  alertsSub() {
    if (this.alertsCount > 0) {
      this.alertsCount--;
    }
  }

  alertsAdd() {
    this.alertsCount++;
  }

  lowWaterCountSub() {
    if (this.lowWaterCount > 0) {
      this.lowWaterCount = this.lowWaterCount - 500;
    }
  }

  lowWaterCountAdd() {
    this.lowWaterCount = this.lowWaterCount + 500;
  }


  dailyUsageCountSub() {
    if (this.dailyUsageCount > 0) {
      this.dailyUsageCount = this.dailyUsageCount - 50;
    }
  }

  dailyUsageCountAdd() {
    this.dailyUsageCount = this.dailyUsageCount + 50;
  }



  batteryCountSub() {
    if (this.batteryCount > 0) {
      this.batteryCount--;
    }
  }

  batteryCountAdd() {
    this.batteryCount++;
  }


  UpdateSettings() {
    document.getElementById("tempButton").click();
  }

  submit() {
    var self = this;
    document.getElementById("timeModal").click();
    self.UpdateNotificationSettings();
  }

  cancel() {
    document.getElementById("timeModal").click();
  }

  UpdateNotificationSettings() {


    var self = this;
    self.loading = true;

    firebase.database().ref().child('notificationsSettings')
      .once('value', function (snapshot) {
        var notiSettings = snapshot.val();
        for (var key in notiSettings) {
          var notiSetting = notiSettings[key];
          if (notiSetting.uid == localStorage.getItem("uid")) {
            self.alreadyExists = true;

            //Update Here Directly..

            notiSetting.uid = localStorage.getItem("uid");
            notiSetting.alertsCount = self.alertsCount;
            notiSetting.dailyUsageCount = self.dailyUsageCount;
            notiSetting.lowWaterCount = self.lowWaterCount;
            notiSetting.batteryCount = self.batteryCount;
            notiSetting.notiAlertsCbox = self.notiAlertsCbox || false,
              notiSetting.lowWaterCbox = self.lowWaterCbox || false,
              notiSetting.dailyUsageCbox = self.dailyUsageCbox || false,
              notiSetting.wifiAlertsCbox = self.wifiAlertsCbox || false,
              notiSetting.timestamp = Number(new Date());

            var updates = {};
            updates['/notificationsSettings/' + key] = notiSetting;
            firebase.database().ref().update(updates);
          }
        }

        if (self.alreadyExists) {

          self.loading = false;
        } else {

          self.createNewNotificationsSetting();
        }
      });
  }


  createNewNotificationsSetting() {

    var self = this;
    var postData: any = {
      uid: localStorage.getItem("uid"),
      alertsCount: self.alertsCount,
      lowWaterCount: self.lowWaterCount,
      dailyUsageCount: self.dailyUsageCount,
      batteryCount: self.batteryCount,
      notiAlertsCbox: self.notiAlertsCbox,
      lowWaterCbox: self.lowWaterCbox,
      dailyUsageCbox: self.dailyUsageCbox,
      wifiAlertsCbox: self.wifiAlertsCbox,
      timestamp: Number(new Date()),
    };

    var newPostKey = firebase.database().ref().child('notificationsSettings').push().key;

    // Write the new post's data simultaneously in the posts list and the user's post list.
    var updates = {};
    updates['/notificationsSettings/' + newPostKey] = postData;
    firebase.database().ref().update(updates).then(() => {
      self.loading = false;
    });
  }


}

import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';

import * as firebase from 'firebase';
import { Router } from '@angular/router';

@Component({
  selector: 'app-signup-step3',
  templateUrl: './signup-step3.component.html',
  styleUrls: ['./signup-step3.component.css']
})
export class SignupStep3Component implements OnInit {
  public addressInput: boolean = false;

  companyName: string = "";

  constructor(public dataService: DataService,
    public router: Router) {
    if (dataService.role === "MANAGER") {
      this.addressInput = false;
    }
  }

  ngOnInit() {

  }

  saveCompany() {
    if (!this.companyName) {
      return alert("Please enter company name!");
    }

    if (localStorage.getItem("currUserKey")) {
      var key = localStorage.getItem("currUserKey");

      firebase.database().ref(`/users/${key}/companyName`).set(this.companyName);

      this.router.navigate(['/customers']);
    } else {

    }
  }

}

import { Component, OnInit } from "@angular/core";
import * as firebase from "firebase";
import { from } from "rxjs";
import * as moment from "moment";
import { DataService } from "../data.service";
import { ActivatedRoute } from "@angular/router";
import { HttpClient } from "@angular/common/http";
declare var jQuery: any;

@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.css"],
})
export class SettingsComponent implements OnInit {
  public tankSettings: any = {};
  public deviceId: any;
  currentIndex = 0;
  optionForDataDeletion: string = "";
  startDate: any;
  newIndex: any = 0;
  enableLeakAlert: any = false;
  uid: string;

  allTanks: Array<any> = [];
  allDevices: any = {};

  deviceSettings: any = {
    publishFreq: 2,
  };

  firstTime: any;

  newDeviceId: any;
  loading: any = false;
  deviceData: any;
  offset: any = 0;
  thresholdHeight: any;
  userOffset: any;

  teamId: string = "";

  constructor(
    public service: DataService,
    public route: ActivatedRoute,
    private http: HttpClient
  ) {
    this.service.activeTab = "settings";

    route.params.subscribe((params) => {
      this.uid = params.id || localStorage.getItem("uid");

      this.teamId = params.id;

      this.getDeviceSetting();
      this.getAllTanks();
    });

    /* this.uid = localStorage.getItem("uid");
    if (localStorage.getItem("deviceId")) {
      this.deviceId = localStorage.getItem("deviceId");
    } else if (localStorage.getItem("deviceId") == undefined) {
      this.deviceId = "No Device associated";
    } */
  }

  ngOnInit() {}

  async deleteSensorData() {
    if (
      this.optionForDataDeletion === "allData" ||
      (this.optionForDataDeletion === "seletedDate" && this.startDate)
    ) {
      this.loading = true;
      await this.http
        .post(
          "https://us-central1-tankmate-7ddc1.cloudfunctions.net/deleteSensorData-deleteSensorData",
          {
            dataDeleteOption: this.optionForDataDeletion,
            date: this.startDate,
            deviceId: this.allTanks[this.newIndex].deviceId,
          },
          { responseType: "text" }
        )
        .subscribe(
          (res) => {
            if (res) {
              this.loading = false;
              jQuery("#deleteSensorData").modal("hide");
              alert("Sensor Data is successfully deleted");
            } else {
              this.loading = false;
              alert("Something went wrong, Please try again");
            }
          },
          (error) => {
            alert("Something went wrong, Please try again");
            this.loading = false;
          }
        );
    } else {
      if (!this.optionForDataDeletion) {
        alert("Please select one option for deleting the sensor data");
      } else if (
        !(this.optionForDataDeletion === "allData") &&
        !this.startDate
      ) {
        alert("Please select date");
      }
    }
  }

  getAllTanks() {
    this.loading = true;
    firebase
      .database()
      .ref("/tankSettings")
      .orderByChild("uid")
      .equalTo(this.uid)
      .once("value")
      .then((snapshot) => {
        var data = snapshot.val();
        for (var key in data) {
          var temp = data[key];

          temp.key = key;

          this.allTanks.push(temp);
          this.getDeviceStates(temp);

          if (!this.allDevices[temp.deviceId]) {
            this.allDevices[temp.deviceId] = {
              publishFreq: 2,
            };

            firebase
              .database()
              .ref(`/deviceSettings/${temp.deviceId}/publishFreq`)
              .set(2);
          }

          if (!this.allDevices[temp.deviceId].publishFreq) {
            this.allDevices[temp.deviceId].publishFreq = 2;
            firebase
              .database()
              .ref(`/deviceSettings/${temp.deviceId}/publishFreq`)
              .set(2);
          }
        }

        if (this.allTanks.length === 1 && this.newDeviceId) {
          firebase
            .database()
            .ref(`/tankSettings/${this.allTanks[0].key}/isDefault`)
            .set(true);

          this.allTanks[0].isDefault = true;

          firebase
            .database()
            .ref("users")
            .orderByChild("uid")
            .equalTo(this.uid)
            .once("value")
            .then((snapshot) => {
              var data = snapshot.val();

              for (var key in data) {
                firebase
                  .database()
                  .ref(`/users/${key}/deviceId`)
                  .set(this.newDeviceId);
              }
            });

          this.deviceId = this.newDeviceId;
          localStorage.setItem("deviceId", this.newDeviceId);
        }
        this.loading = false;
        this.getDeviceData();
        console.log(this.allTanks);
      });
  }

  getDeviceStates(temp) {
    firebase
      .database()
      .ref()
      .child("devices/" + temp.deviceId)
      .once("value", (snapshot) => {
        var data = snapshot.val();
        temp.deviceInfo = data;
      });
  }

  getDeviceData() {
    this.getReadingOfSpecificDevice(
      this.allTanks[this.currentIndex].deviceId
    ).then((data) => {
      this.deviceData = data || [];
      this.calculateCurrentVolume();
    });
  }

  calculateCurrentVolume() {
    var length = this.deviceData.length;
    //Last-Reading Calculations//
    var lastReading = this.deviceData[length - 1].published_at;
    var mydate = new Date(lastReading);
    //this.lastReadingStr = year + "/" + month + "/" + day + ' ' + ' ' + hours + ":" + min;
    this.allTanks[this.currentIndex].lastReadingDate = moment(mydate).format(
      "ddd D/MM [at] HH:mm"
    );
  }

  getReadingOfSpecificDevice(deviceId): Promise<any> {
    return firebase
      .database()
      .ref()
      .child(`deviceData/${deviceId}`)
      .once("value")
      .then((snapshot) => {
        var deviceData = [];

        var userDevice = snapshot.val();

        for (var data in userDevice) {
          var temp = userDevice[data];

          if (temp.data.indexOf("waterLevel") >= 0) {
            continue;
          }

          if (deviceData.length > 0) {
            var currentDate = new Date(temp.published_at);
            var lastDate = new Date(
              deviceData[deviceData.length - 1].published_at
            );

            if (currentDate.getDate() === lastDate.getDate()) {
              deviceData[deviceData.length - 1] = temp;
            } else {
              deviceData.push(temp);
            }
          } else {
            deviceData.push(temp);
          }
        }

        return deviceData;
      });
  }

  getDeviceSetting() {
    return firebase
      .database()
      .ref(`/deviceSettings`)
      .once("value")
      .then((snapshot) => {
        if (snapshot.val()) {
          this.allDevices = snapshot.val();
          for (var key in this.allDevices) {
            var device = this.allDevices[key];
            if (device) {
              if (device.value) {
                if (device.value.thresholdHeight) {
                  device.value.thresholdHeight =
                    device.value.thresholdHeight * 1000;
                }
                if (device.value.userOffset) {
                  device.value.userOffset = device.value.userOffset * 1000;
                }
              }
            }
          }
        }


        return true;
      });
  }

  updateSettings(index) {
    if (
      !this.allTanks[index].tankDiameter ||
      !this.allTanks[index].outletHeight ||
      !this.allTanks[index].overflowHeight ||
      !this.allTanks[index].tankQuantity ||
      !this.allTanks[index].deviceId ||
      
      (this.allTanks[index].deviceType == 'v3' &&
        !this.allDevices[this.allTanks[index].deviceId].value.sensorHeight)
    ) {
      return alert("Please complete tank settings with deviceID.");
    }

    this.calculateMaximumUsableVolume(index);
  }

  calculateMaximumUsableVolume(index) {
    //Lets Convert These To Numbers First as Applied Mask..
    this.allTanks[index].tankDiameter = Number(
      this.allTanks[index].tankDiameter
    );
    this.allTanks[index].tankQuantity = Number(
      this.allTanks[index].tankQuantity
    );
    this.allTanks[index].overflowHeight = Number(
      this.allTanks[index].overflowHeight
    );
    this.allTanks[index].outletHeight = Number(
      this.allTanks[index].outletHeight
    );
    this.allTanks[index].tankArea = Number(this.allTanks[index].tankArea);
    this.allTanks[index].maximumHeight = Number(
      this.allTanks[index].maximumHeight
    );
    this.allTanks[index].maxVolume = Math.round(
      Number(this.allTanks[index].maxVolume)
    );

    var tankDiameterSquare = Math.pow(this.allTanks[index].tankDiameter, 2);
    var temp = (3.1416 * tankDiameterSquare) / 4;

    this.allTanks[index].tankArea = temp * this.allTanks[index].tankQuantity;
    this.allTanks[index].maximumHeight =
      this.allTanks[index].overflowHeight -
      this.allTanks[index].outletHeight / 1000;
    this.allTanks[index].maxVolume = Math.round(
      this.allTanks[index].tankArea * this.allTanks[index].maximumHeight * 1000
    );
    //this.saveDataStorage();
    this.saveDeviceSettings(index);
    this.saveToFirebase(index);
    this.saveAdditionalSettings(index);
  }

  saveDeviceSettings(index) {
    this.allDevices[this.allTanks[index].deviceId].publishFreq = Number(
      this.allDevices[this.allTanks[index].deviceId].publishFreq
    );
    //this.deviceSettings.deviceId = this.deviceId;
    if (this.allDevices[this.allTanks[index].deviceId].value.userOffset) {
      firebase
        .database()
        .ref(
          `/deviceSettings/${this.allTanks[index].deviceId}/value/userOffset`
        )
        .set(this.allDevices[this.allTanks[index].deviceId].value.userOffset);
    }

    if (this.allTanks[index].deviceType === 'v3') {
      firebase
      .database()
      .ref(`/deviceSettings/${this.allTanks[index].deviceId}/value/sensorHeight`)
      .set(this.allDevices[this.allTanks[index].deviceId].value.sensorHeight || 0,);
    }

    firebase
      .database()
      .ref(`/deviceSettings/${this.allTanks[index].deviceId}/publishFreq`)
      .set(this.allDevices[this.allTanks[index].deviceId].publishFreq);
    firebase
      .database()
      .ref(`/deviceSettings/${this.allTanks[index].deviceId}/deviceId`)
      .set(this.allDevices[this.allTanks[index].deviceId].deviceId);
  }

  saveAdditionalSettings(index) {
    if (this.enableLeakAlert) {
      this.allDevices[this.allTanks[index].deviceId].value.enableLeakAlert =
        this.enableLeakAlert;
    }
    if (this.userOffset) {
      this.allDevices[this.allTanks[index].deviceId].value.userOffset =
        this.userOffset;
    }

    if (this.thresholdHeight) {
      this.allDevices[this.allTanks[index].deviceId].value.thresholdHeight =
        this.thresholdHeight;
    }
    var thresholdHeight =
      this.allDevices[this.allTanks[index].deviceId].value.thresholdHeight /
      1000;
    var userOffset =
      this.allDevices[this.allTanks[index].deviceId].value.userOffset / 1000;
    firebase
      .database()
      .ref(
        `/deviceSettings/${this.allTanks[index].deviceId}/value/enableLeakAlert`
      )
      .set(
        this.allDevices[this.allTanks[index].deviceId].value.enableLeakAlert ||
          false
      );
    firebase
      .database()
      .ref(`/deviceSettings/${this.allTanks[index].deviceId}/value/userOffset`)
      .set(userOffset || 0);
    firebase
      .database()
      .ref(
        `/deviceSettings/${this.allTanks[index].deviceId}/value/thresholdHeight`
      )
      .set(thresholdHeight || 0);
  }

  saveDataStorage() {
    localStorage.setItem(
      "estimatedAvgUsage",
      this.tankSettings.estimatedAvgUsage
    );
    localStorage.setItem("outletHeight", this.tankSettings.outletHeight);
    localStorage.setItem("overflowHeight", this.tankSettings.overflowHeight);
    localStorage.setItem("tankQuantity", this.tankSettings.tankQuantity);
    localStorage.setItem("tankDiameter", this.tankSettings.tankDiameter);
    localStorage.setItem("tankArea", this.tankSettings.tankArea);
    localStorage.setItem("maximumHeight", this.tankSettings.maximumHeight);
    localStorage.setItem("maxVolume", this.tankSettings.maxVolume);
  }

  saveToFirebase(index) {
    var key = this.allTanks[index].key;

    if (!key) {
      key = firebase.database().ref(`/tankSettings`).push().key;
    }

    this.allTanks[index].uid = localStorage.getItem("uid");

    //delete this.tankSettings.key;
    var updates = {};
    updates["/tankSettings/" + key] = this.allTanks[index];
    firebase
      .database()
      .ref()
      .update(updates)
      .then(() => {
        alert("Tank settings updated.");
      });
  }
  changeTank(event?) {
    if (this.newIndex === -1 || this.newIndex === "-1") {
      this.newIndex = this.currentIndex;
    } else {
      if (!this.allDevices[this.allTanks[this.newIndex].deviceId])
        this.allDevices[this.allTanks[this.newIndex].deviceId] = {
          publishFreq: 2,
        };

      if (!this.allDevices[this.allTanks[this.newIndex].deviceId].publishFreq)
        this.allDevices[this.allTanks[this.newIndex].deviceId].publishFreq = 2;

      this.currentIndex = Number(this.newIndex);
      this.getDeviceData();
    }
  }
}

import { Component, OnInit } from "@angular/core";
import * as firebase from "firebase";

@Component({
  selector: "app-alerts",
  templateUrl: "./alerts.component.html",
  styleUrls: ["./alerts.component.css"],
})
export class AlertsComponent implements OnInit {
  public allTank = [1, 2, 3, 4];

  public loading: any;
  addEmail = false;
  editEmails = false;
  allTanks: any = [];
  currentTank: any = {};
  sensorDeviceSettings: any = {};
  editSensorDeviceSettings: any = {};
  constructor() {}

  ngOnInit() {
    this.loading = true;
    this.getAllTanks();
  }

  getAllTanks() {
    this.allTanks = [];
    this.loading = true;
    firebase
      .database()
      .ref("/tankSettings")
      .orderByChild("uid")
      .equalTo(localStorage.getItem("uid"))
      .once("value")
      .then((snapshot) => {
        this.loading = false;
        var data = snapshot.val();
        for (var key in data) {
          var temp = data[key];
          temp.key = key;
          this.allTanks.push(temp);
        }
        this.currentTank = this.allTanks[0];
        if (this.allTanks.length) {
          this.allTanks.forEach(async (tank) => {
            await this.getDeviceSetting(tank);
          });
        } else {
          this.loading = false;
        }
      })
      .catch((error) => {
        this.loading = false;
        console.log("error", error);
      });
  }

  async getDeviceSetting(tankData) {
    await firebase
      .database()
      .ref(`/deviceSettings/${tankData.deviceId}`)
      .once("value")
      .then((snapshot) => {
        const deviceSetting = snapshot.val();
        if (deviceSetting.emailSettings) {
          this.sensorDeviceSettings[deviceSetting.deviceId] = JSON.parse(
            JSON.stringify(deviceSetting.emailSettings)
          );
          this.editSensorDeviceSettings[deviceSetting.deviceId] = JSON.parse(
            JSON.stringify(deviceSetting.emailSettings)
          );
        } else {
          this.sensorDeviceSettings[deviceSetting.deviceId] = {
            emailIds: [],
            lowTankLevel: {
              isNotification: false,
              value: 10000,
            },
            highTanklevel: {
              isNotification: false,
              value: 20000,
            },
          };
          this.editSensorDeviceSettings = JSON.parse(
            JSON.stringify(this.sensorDeviceSettings)
          );
        }
        return;
      });
  }

  updateSettings() {
    this.loading = true;
    firebase
      .database()
      .ref(`/deviceSettings/${this.currentTank.deviceId}/emailSettings`)
      .set(this.editSensorDeviceSettings[this.currentTank.deviceId])
      .then((result) => {
        this.loading = false;
        this.getAllTanks();
      })
      .catch((error) => {
        this.loading = false;
        console.log("error");
      });
  }
  addMail(mail) {
    const emailRegex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;

    const regex = new RegExp(emailRegex);
    if (regex.test(mail)) {
      this.addEmail = !this.addEmail;
      if (this.sensorDeviceSettings[this.currentTank.deviceId].emailIds) {
        this.sensorDeviceSettings[this.currentTank.deviceId].emailIds.push(
          mail
        );
      } else {
        this.sensorDeviceSettings[this.currentTank.deviceId].emailIds = [];
        this.sensorDeviceSettings[this.currentTank.deviceId].emailIds.push(
          mail
        );
      }
      this.editSensorDeviceSettings = JSON.parse(
        JSON.stringify(this.sensorDeviceSettings)
      );
      const updateObject = {
        emailIds: JSON.parse(
          JSON.stringify(
            this.editSensorDeviceSettings[this.currentTank.deviceId].emailIds
          )
        ),
        lowTankLevel: JSON.parse(
          JSON.stringify(
            this.sensorDeviceSettings[this.currentTank.deviceId].lowTankLevel
          )
        ),
        highTanklevel: JSON.parse(
          JSON.stringify(
            this.sensorDeviceSettings[this.currentTank.deviceId].highTanklevel
          )
        ),
      };
      this.loading = true;
      firebase
        .database()
        .ref(`/deviceSettings/${this.currentTank.deviceId}/emailSettings`)
        .set(updateObject)
        .then((result) => {
          this.loading = false;
          this.getAllTanks();
        })
        .catch((error) => {
          this.loading = false;
          console.log("error");
        });
    } else {
      alert("Please enter valid email");
    }
  }
  saveMail() {
    const emailList = JSON.parse(
      JSON.stringify(
        this.editSensorDeviceSettings[
          this.currentTank.deviceId
        ].emailIds.filter((n) => n)
      )
    );
    this.sensorDeviceSettings[this.currentTank.deviceId].emailIds = JSON.parse(
      JSON.stringify(emailList)
    );
    this.editSensorDeviceSettings[this.currentTank.deviceId].emailIds =
      JSON.parse(JSON.stringify(emailList));
    const updateObject = {
      emailIds: JSON.parse(
        JSON.stringify(
          this.editSensorDeviceSettings[this.currentTank.deviceId].emailIds
        )
      ),
      lowTankLevel: JSON.parse(
        JSON.stringify(
          this.sensorDeviceSettings[this.currentTank.deviceId].lowTankLevel
        )
      ),
      highTanklevel: JSON.parse(
        JSON.stringify(
          this.sensorDeviceSettings[this.currentTank.deviceId].highTanklevel
        )
      ),
    };
    this.loading = true;
    firebase
      .database()
      .ref(`/deviceSettings/${this.currentTank.deviceId}/emailSettings`)
      .set(updateObject)
      .then((result) => {
        this.loading = false;
        this.getAllTanks();
      })
      .catch((error) => {
        this.loading = false;
        console.log("error");
      });
  }

  cancelEditMail() {
    this.editSensorDeviceSettings[this.currentTank.deviceId].emailIds =
      JSON.parse(
        JSON.stringify(
          this.sensorDeviceSettings[this.currentTank.deviceId].emailIds
        )
      );
  }
}

import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { DataService } from '../data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.css']
})
export class SidemenuComponent implements OnInit {

  constructor(public service: DataService,
    public cd: ChangeDetectorRef,
    public router: Router) {

  }

  ngOnInit() {
  }

  changeTab(data) {
    this.service.activeTab = data;
    if (data == 'dashboard') {
      this.router.navigate(['/dashboard']);
    }
    else if (data == 'settings') {
      this.router.navigate(['/settings']);
    }
    else if (data == 'customers') {
      this.router.navigate(['/customers']);
    }
    else if (data == 'alerts') {
      this.router.navigate(['/alerts']);
    }
    else {
      this.router.navigate(['/completeProfile']);
    }

    this.cd.detectChanges();
    this.cd.markForCheck();
  }

}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import * as firebase from 'firebase';
import { AppComponent } from './app.component';
import { AuthComponent } from './auth/auth.component';
import { HeaderComponent } from './header/header.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardComponent } from './dashboard/dashboard.component';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { ChartsModule } from 'ng2-charts';
import { HttpClientModule } from '@angular/common/http';
import { NotificationsComponent } from './notifications/notifications.component';
import { LoadingModule } from 'ngx-loading';
import { SettingsComponent } from './settings/settings.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { NgxMaskModule } from 'ngx-mask';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { NgxGaugeModule } from 'ngx-gauge';
import { CompleteProfileComponent } from './complete-profile/complete-profile.component';
import { Ng4GeoautocompleteModule } from 'ng4-geoautocomplete';
import { SidemenuComponent } from './sidemenu/sidemenu.component';
//import { TooltipModule } from 'ng2-tooltip-directive'
import { TooltipModule } from 'ngx-better-tooltip';
import { SignupStep1Component } from './signup-step1/signup-step1.component';
import { SignupStep2Component } from './signup-step2/signup-step2.component';
import { SignupStep3Component } from './signup-step3/signup-step3.component';
import { CustomersComponent } from './customers/customers.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AlertsComponent } from './alerts/alerts.component';
const appRoutes: Routes = [
  { path: 'login', component: AuthComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'dashboard/:id', component: DashboardComponent },
  { path: 'notifications', component: NotificationsComponent },
  { path: 'settings', component: SettingsComponent },
  { path: 'settings/:id', component: SettingsComponent },
  { path: 'signup', component: SignUpComponent },
  { path: 'resetPass', component: ResetPasswordComponent },
  { path: 'completeProfile', component: CompleteProfileComponent },
  { path: 'signup-step1', component: SignupStep1Component },
  { path: 'signup-step2', component: SignupStep2Component },
  { path: 'signup-step3', component: SignupStep3Component },
  { path: 'customers', component: CustomersComponent },
  { path: 'alerts', component: AlertsComponent },
  //{ path: '',   redirectTo: '/dashboard', pathMatch: 'full' },

  { path: '', redirectTo: '/login', pathMatch: 'full' },
];

export const config = {
  apiKey: "AIzaSyBxvzYYLvc0ZqzgRcZaMnaA5iqNCoP5Ke0",
  authDomain: "tankmate-7ddc1.firebaseapp.com",
  databaseURL: "https://tankmate-7ddc1.firebaseio.com",
  projectId: "tankmate-7ddc1",
  storageBucket: "",
  messagingSenderId: "785828154762"
};
firebase.initializeApp(config);

firebase.auth().onAuthStateChanged(function (user) {
  if (user) {
    // User is signed in.

    // ...
  } else {
    // User is signed out.
    // ...
  }
});


@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    HeaderComponent,
    DashboardComponent,
    NotificationsComponent,
    SettingsComponent,
    SignUpComponent,
    ResetPasswordComponent,
    CompleteProfileComponent,
    SidemenuComponent,
    SignupStep1Component,
    SignupStep2Component,
    SignupStep3Component,
    CustomersComponent,
    AlertsComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    TooltipModule,
    InfiniteScrollModule,
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: false } // <-- debugging purposes only
    ),
    BrowserModule,
    RoundProgressModule,
    ChartsModule,
    LoadingModule,
    HttpClientModule,
    NgxGaugeModule,
    Ng4GeoautocompleteModule.forRoot(),
  ],
  providers: [],
  bootstrap: [AppComponent]
})

export class AppModule { }

import { Component, OnInit, NgZone } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import * as firebase from "firebase";

import * as moment from "moment";
import { ActivatedRoute, Router } from "@angular/router";
import { DataService } from "../data.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent implements OnInit {
  total: any = {
    currentVolume: 0,
    maxVolume: 0,
    percentVolume: 0,
    waterDays: 0,
    avgUsage: 0,
  };
  startDate: any;
  endDate: any;
  public monthNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  public userState: any;
  public dailUsageTodayDate: any;
  public deviceId: any;
  public currentVolume: any = 0;
  public deviceData: Array<any> = [];
  public deviceDataObj: any;
  public waterLevel: any;
  public loading: any;
  public waterDays: any;
  public percentVolume: any;
  public yesterdayVolume: any;
  public lastReadingStr: any;
  public avgCalculation: any;
  deviceState: any = {};
  public dailyUsage: any = {
    val: 0,
    percent: 0,
  };
  public yesterdayWaterLevel: any;
  public waterUsageLast14Days: any = {
    val: 0,
    percent: 0,
  };
  public waterUsageLast7Days: any = {
    val: 0,
    percent: 0,
  };
  public waterUsageLast30Days: any = {
    val: 0,
    percent: 0,
  };
  public averageUsageOption: any = 7;
  public waterUsageArray: Array<any> = [];
  public waterUsageDateArray: Array<any> = [];
  //Simple Settings Containers..
  public estimatedAvgUsage: any = 0;
  public outletHeight: any = 0;
  public overflowHeight: any = 0;
  public tankQuantity: any = 0;
  public tankDiameter: any = 0;
  public tankArea: any = 0;
  public maximumHeight: any = 0;
  public maxVolume: any = 0;
  public avgEst: any = "est";
  public duration: any = 5;
  selectedTank: any;
  // Declarations for Bar Graph..
  public barChartOptions: any = {
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    responsive: true,
    //bezierCurve : false,
    elements: {
      line: {
        tension: 0, // disables bezier curves
      },
    },
    scaleShowVerticalLines: false,
  };
  //Tank Level Content Graph 1
  public barChartLabels: Array<any> = [];
  public barChartData: any[];
  public barChartType: string = "line";
  public barChartLegend: boolean = true;
  public graphRecords: any = 7;
  public chartColors: Array<any> = [
    {
      // Water level
      //backgroundColor: '#2daddc',
      backgroundColor: "rgba(132, 149, 221, 0.5)",
      borderColor: "#8495dd",
      pointBackgroundColor: "rgba(45, 173, 220, 0)",
      pointBorderColor: "rgba(45, 173, 220, 0)",
    },
    {
      // Precip
      backgroundColor: "#969fa4",
    },
  ];

  //Water Level Content Graph 2
  public barChartLabelsWU: Array<any> = [];
  public barChartDataWU: any[];
  public barChartTypeWU: string = "bar";
  public barChartLegendWU: boolean = true;
  public graphRecordsWU: any = 7;
  public chartColorsWU: Array<any> = [
    {
      // first color
      backgroundColor: "#2daddc",
    },
  ];
  usage: any = false;
  currentTank: any;
  allDates: Array<any> = [];
  allUsage: Array<any> = [];

  public barChartLabelsWU7: Array<any> = [];
  public barChartLabelsWU14: Array<any> = [];
  public barChartLabelsWU30: Array<any> = [];
  public barChartDataWU7: any[];
  public barChartDataWU14: any[];
  public barChartDataWU30: any[];
  //Declarations For forecastData
  public forecastData: Array<any> = [];
  private days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  private weatherbitKey: string = "d7fd306fd7f64bcf9d98f290a7695ad5";
  private dailyWeatherUrl: string =
    "https://api.weatherbit.io/v2.0/history/daily";
  private forecastWeatherUrl: string =
    "https://api.weatherbit.io/v2.0/forecast/daily";

  private Beachlands: any = {
    lat: -36.9030909,
    lon: 175.0062614,
  };
  //These are all for gague..
  public gaugeType = "arch";
  public gaugeValue: any;
  public gaugeAppendText = "%";
  public min = 0;
  public max = 100;
  public value = 50;
  public thick = 15;
  public foregroundColor = "rgb(54, 174, 218)";
  public backgroundColor = "#dddddd";

  public postalCode: any = "2018";

  public precipData: any = {};

  allTanks: any = [];
  tankDates: any = [];
  tankData: any = [];
  custom: any;
  barChartLabelsW: any[];
  barChartDataW: { data: any[]; label: string }[];
  currentTankWater: any;
  tankfullDates: any = [];
  barChartLabelsFullDate: any = [];
  tankDatesShort: any = [];

  teamId: string = "";

  constructor(
    private http: HttpClient,
    public router: Router,
    public zone: NgZone,
    public service: DataService,
    public route: ActivatedRoute
  ) {
    this.service.activeTab = "dashboard";
    if (!localStorage.getItem("uid")) {
      window.location.assign("/");
      //router.navigate(['/login']);
      return;
    }

    var x = document.getElementById("myNav");
    x.style.display = "block";

    if (localStorage.getItem("oneTimeReload") == "true") {
      location.reload();
      localStorage.setItem("oneTimeReload", "false");
    }

    route.params.subscribe((params) => {
      if (params.id) {
        this.teamId = params.id;
        this.getTeamUserData();
      } else {
        this.getCurrentUserData();
      }
    });
  }

  ngOnInit() {}

  getCurrentUserData() {
    this.userState = localStorage.getItem("userState");
    this.estimatedAvgUsage = Number(localStorage.getItem("estimatedAvgUsage"));
    this.outletHeight = Number(localStorage.getItem("outletHeight"));
    this.overflowHeight = Number(localStorage.getItem("overflowHeight"));
    this.tankQuantity = Number(localStorage.getItem("tankQuantity"));
    this.tankDiameter = Number(localStorage.getItem("tankDiameter"));

    this.tankArea = Number(localStorage.getItem("tankArea"));
    this.maximumHeight = Number(localStorage.getItem("maximumHeight"));
    this.maxVolume = Number(localStorage.getItem("maxVolume"));
    this.avgEst = localStorage.getItem("avgEst") || "est";
    if (localStorage.getItem("postalCode")) {
      this.postalCode = localStorage.getItem("postalCode");
    }
    this.maxVolume = Math.round(this.maxVolume);
    this.getPrecipData();
    if (localStorage.getItem("deviceId")) {
      this.deviceId = localStorage.getItem("deviceId");
    }

    if (this.deviceId) {
      if (this.deviceId != "undefined") {
        // this.getForecast();
        this.getAllTanks();
      }
    }
  }

  async getTeamUserData() {
    var self = this;

    firebase
      .database()
      .ref()
      .child("users")
      .orderByChild("uid")
      .equalTo(this.teamId)
      .once("value", (snapshot) => {
        var users = snapshot.val();
        for (var key in users) {
          var user = users[key];
          if (user.uid == this.teamId) {
            self.loading = false;

            this.deviceId = user.deviceId;
            this.userState = user.state;

            if (user.postalCode) {
              this.postalCode = user.postalCode;
            }

            self.receiveTankSettings();
          }
        }
      });
  }

  receiveTankSettings() {
    var self = this;
    self.loading = true;

    firebase
      .database()
      .ref()
      .child("tankSettings")
      .orderByChild("uid")
      .equalTo(this.teamId)
      .once("value", (snapshot) => {
        var tankSettings = snapshot.val();

        var defaultTank: any = "",
          found = false;

        for (var key in tankSettings) {
          var tankSetting = tankSettings[key];

          if (!defaultTank) {
            defaultTank = tankSetting;
            defaultTank.key = key;
          }

          if (tankSetting.deviceId === this.deviceId) {
            this.estimatedAvgUsage = tankSetting.estimatedAvgUsage;
            this.outletHeight = tankSetting.outletHeight;
            this.overflowHeight = tankSetting.overflowHeight;
            this.tankQuantity = tankSetting.tankQuantity;
            this.tankDiameter = tankSetting.tankDiameter;
            this.tankArea = tankSetting.tankArea;
            this.maximumHeight = tankSetting.maximumHeight;
            this.maxVolume = tankSetting.maxVolume;
            this.avgEst = tankSetting.avgEst;

            found = true;

            this.maxVolume = Math.round(this.maxVolume);
            this.getPrecipData();

            if (this.deviceId) {
              if (this.deviceId != "undefined") {
                // this.getForecast();
                this.getAllTanks();
              }
            }

            self.loading = false;
            return;
          }
        }

        if (!found && defaultTank) {
          this.estimatedAvgUsage = defaultTank.estimatedAvgUsage;
          this.outletHeight = defaultTank.outletHeight;
          this.overflowHeight = defaultTank.overflowHeight;
          this.tankQuantity = defaultTank.tankQuantity;
          this.tankDiameter = defaultTank.tankDiameter;
          this.tankArea = defaultTank.tankArea;
          this.maximumHeight = defaultTank.maximumHeight;
          this.maxVolume = defaultTank.maxVolume;
          this.avgEst = defaultTank.avgEst;

          defaultTank.deviceId = this.deviceId;
          defaultTank.isDefault = true;

          firebase
            .database()
            .ref(`/tankSettings/${defaultTank.key}`)
            .set(defaultTank);
        }

        this.maxVolume = Math.round(this.maxVolume);
        this.getPrecipData();

        if (this.deviceId) {
          if (this.deviceId != "undefined") {
            // this.getForecast();
            this.getAllTanks();
          }
        }

        self.loading = false;
      });
  }

  exportData() {
    let temp = [];
    let header = ["coreid", "data", "published_at"];
    for (var i = 0; i < this.barChartData[0].data.length; i++) {
      var lastReading = this.deviceData[i].published_at;
      var mydate = new Date(lastReading);
      var month = mydate.getMonth() + 1;
      var day = mydate.getDate();
      var year = mydate.getUTCFullYear();
      var hours = mydate.getHours();
      var min = mydate.getMinutes();
      var lastReadingStr =
        year + "/" + month + "/" + day + " " + " " + hours + ":" + min;
      temp.push({
        coreid: this.deviceData[i].coreid,
        data: this.barChartData[0].data[i],
        published_at: this.barChartLabelsFullDate[i],
      });
    }

    this.downloadFile(temp, this.deviceData[0].coreid, header);
  }

  ConvertToCSV(objArray, headerList) {
    let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let str = "";
    let row = "S.No,";

    for (let index in headerList) {
      row += headerList[index] + ",";
    }
    row = row.slice(0, -1);
    str += row + "\r\n";
    for (let i = 0; i < array.length; i++) {
      let line = i + 1 + "";
      for (let index in headerList) {
        let head = headerList[index];

        line += "," + array[i][head];
      }
      str += line + "\r\n";
    }
    return str;
  }

  downloadFile(data, filename, headers) {
    let csvData = this.ConvertToCSV(data, headers);

    let blob = new Blob(["\ufeff" + csvData], {
      type: "text/csv;charset=utf-8;",
    });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser =
      navigator.userAgent.indexOf("Safari") != -1 &&
      navigator.userAgent.indexOf("Chrome") == -1;
    if (isSafariBrowser) {
      //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  getAllTanks() {
    this.allTanks = [];
    this.loading = true;
    firebase
      .database()
      .ref("/tankSettings")
      .orderByChild("uid")
      .equalTo(this.teamId || localStorage.getItem("uid"))
      .once("value")
      .then((snapshot) => {
        var data = snapshot.val();
        for (var key in data) {
          var temp = data[key];
          temp.key = key;
          this.allTanks.push(temp);
        }
        if (this.allTanks.length) {
          this.allTanks.forEach((tank) => {
            this.getDeviceConfiguration(tank);
          });
        }
        this.selectedTank = this.allTanks[0];
        this.currentTank = JSON.parse(JSON.stringify(this.allTanks[0]));
        //this.calculateCurrentVolumeOfTank(this.currentTank);
        this.currentTankWater = "All Tanks";
        this.getAllData();
      });
  }

  getDeviceConfiguration(tank: { deviceId: any; }) {
    this.deviceState = {};
    firebase
      .database()
      .ref(`/devices/${tank.deviceId}/state`)
      .once("value", (snapshot) => {
        var data = snapshot.val();

        if (!data) {
          return;
        }

        if (data.batVoltage) {
          if (data.batVoltage < 3300) {
            this.deviceState = data;
          }
        }
      });
  }

  async getPrecipData() {
    try {
      var snapshot = await firebase
        .database()
        .ref()
        .child(`precip/${this.postalCode}`)
        .once("value");

      var precip = snapshot.val();
      if (precip) {
        this.precipData = precip;
      }
    } catch (error) {}
  }

  getDeviceDataTank() {
    var self = this;
    this.tankDates = [];
    this.tankData = [];
    this.tankfullDates = [];
    for (var i = 0; i < this.deviceData.length; i++) {
      var date = new Date();
      var yesterday = new Date(date.getTime());
      yesterday.setDate(date.getDate() - i);
      var readingDate = new Date(
        self.deviceData[this.deviceData.length - i - 1].published_at
      );
      this.tankDates.push(moment(readingDate).format("D/M/YYYY"));
      this.tankDatesShort.push(moment(readingDate).format("D/M"));
      this.tankfullDates.push(
        moment(readingDate).format("MMMM DD YYYY HH:mm:ss")
      );
      this.tankData.push(
        Number(self.deviceData[this.deviceData.length - i - 1].data)
      );
    }
    if (this.custom == "level") {
      this.toFindData();
    }
  }

  waterLevelChartData(count) {
    if (count > 0) {
      var self = this;
      this.custom = "";
      var datesArray = [];
      self.graphRecords = count;
      var end_date = Number(new Date());
      var start_date = end_date - count * 86400000;
      var datesArray = this.getfullAllDates(
        new Date(start_date),
        new Date(end_date)
      );
      self.barChartLabels = [];
      self.barChartData = [
        {
          data: [],
          label: "Water Level",
          type: "line",
          fill: "origin",
        },
      ];
      for (var i = 0; i < this.tankDates.length; i++) {
        if (datesArray.includes(this.tankDates[i])) {
          self.barChartLabels.push(this.tankDatesShort[i]);
          self.barChartLabelsFullDate.push(this.tankfullDates[i]);
          self.barChartData[0].data.push(this.tankData[i]);
        }
      }
      self.barChartData[0].data = self.barChartData[0].data.reverse();
      self.barChartLabels = self.barChartLabels.reverse();
      self.barChartLabelsFullDate = self.barChartLabelsFullDate.reverse();
      var waterLevelFirstReading = Number(self.barChartData[0].data[0]);
      var waterLevelLastReading = Number(
        self.barChartData[0].data[self.barChartData[0].data.length - 1]
      );
      var voulmeFirstReading =
        (waterLevelFirstReading * 1000 - this.outletHeight) * this.tankArea;
      var voulmeLastReading =
        (waterLevelLastReading * 1000 - this.outletHeight) * this.tankArea;
      this.currentTank.waterStats.currentVolume = Math.round(
        voulmeLastReading - voulmeFirstReading
      );
      self.barChartData[0].label = "Water Level";
    }
  }

  // waterLevelChartData(count) {
  //   if (count > 0) {
  //     var self = this;
  //     this.custom = '';
  //     self.graphRecords = count;

  //     self.barChartLabels = [];
  //     self.barChartData = [
  //       {
  //         data: [],
  //         label: 'Water Level',
  //         type: "line",
  //         fill: "origin"
  //       },
  //     ];

  //     var index = 0;

  //     for (var i = 0; self.barChartData[0].data.length < self.graphRecords && index < this.deviceData.length; i++) {
  //       var date = new Date();
  //       var yesterday = new Date(date.getTime());
  //       yesterday.setDate(date.getDate() - i);

  //       var readingDate = new Date(self.deviceData[this.deviceData.length - index - 1].published_at);

  //       if (readingDate.toLocaleDateString() === yesterday.toLocaleDateString()) {
  //         self.barChartData[0].data.push((Number(self.deviceData[this.deviceData.length - index - 1].data)));
  //         index++;
  //       } else {
  //         continue
  //       }

  //       var sampleDate = yesterday.getDate() + "/" + (yesterday.getMonth() + 1);
  //       self.barChartLabels.push(sampleDate.toString());
  //     }

  //     self.barChartData[0].data = self.barChartData[0].data.reverse();
  //     self.barChartLabels = self.barChartLabels.reverse();
  //     var waterLevelFirstReading = Number(self.barChartData[0].data[0]);
  //     var waterLevelLastReading = Number(self.barChartData[0].data[self.barChartData[0].data.length - 1]);
  //     var voulmeFirstReading = (waterLevelFirstReading * 1000 - this.outletHeight) * (this.tankArea);
  //     var voulmeLastReading = (waterLevelLastReading * 1000 - this.outletHeight) * (this.tankArea);
  //     this.currentTank.waterStats.currentVolume = Math.round(voulmeLastReading - voulmeFirstReading);
  //     self.barChartData[0].label = "Water Level";
  //     console.log(this.barChartData);
  //   }

  // }

  waterUsage() {
    var self = this;
    var length = self.deviceData.length;

    var index = 0;
    var nowIndex = 0;
    var tempIndex = 0;
    var currentDateIndex = 0;
    var days = 0;
    for (
      var i = 0;
      i < self.deviceData.length - 1 &&
      index < self.deviceData.length &&
      self.waterUsageArray.length < self.deviceData.length;
      i++
    ) {
      var waterLevel = 0;
      var yesterdayVolume = 0;
      var currentVolume = 0;
      var yesterdayWaterLevel = 0;
      var dailyUsage = 0;
      var precip = 0;

      var date = new Date();
      var yesterday = new Date(date.getTime());
      yesterday.setDate(date.getDate() - (i + 1));

      var precipDate = moment(yesterday).format("YYYY-MM-DD");

      if (this.precipData[precipDate]) {
        precip = this.precipData[precipDate].precip;
      } else {
        precip = 0;
      }

      var now = new Date(date.getTime());
      now.setDate(date.getDate() - (currentDateIndex + nowIndex));
      var sampleDate = now.getDate() + "/" + (now.getMonth() + 1);
      if (index < self.deviceData.length - 1) {
        var currentreadingDate = new Date(
          self.deviceData[length - (index + 1)].published_at
        );
        var yesterdayReadingDate = new Date(
          self.deviceData[length - (index + 2)].published_at
        );
        if (
          now.toLocaleDateString() === currentreadingDate.toLocaleDateString()
        ) {
          if (
            now.toLocaleDateString() ===
              currentreadingDate.toLocaleDateString() &&
            yesterday.toLocaleDateString() ===
              yesterdayReadingDate.toLocaleDateString()
          ) {
            yesterdayWaterLevel = self.deviceData[length - (index + 2)].data;
            yesterdayWaterLevel = Number(yesterdayWaterLevel);
            waterLevel = Number(self.deviceData[length - (index + 1)].data);
            currentVolume =
              (waterLevel * 1000 - self.outletHeight) * self.tankArea;
            yesterdayVolume =
              (yesterdayWaterLevel * 1000 - self.outletHeight) * self.tankArea;
            dailyUsage = yesterdayVolume - currentVolume;
            dailyUsage = Number(dailyUsage.toFixed(1));
            index++;
            nowIndex += tempIndex;
            currentDateIndex++;
            tempIndex = 0;

            self.waterUsageDateArray.push(sampleDate.toString());
            self.waterUsageArray.push(dailyUsage);

            if (days < 7 && dailyUsage > 0 && precip < 1) {
              self.waterUsageLast7Days.val =
                self.waterUsageLast7Days.val + dailyUsage;
              self.waterUsageLast14Days.val =
                self.waterUsageLast14Days.val + dailyUsage;
              self.waterUsageLast30Days.val =
                self.waterUsageLast30Days.val + dailyUsage;
              days++;
            } else if (days < 14 && dailyUsage > 0 && precip < 1) {
              self.waterUsageLast14Days.val =
                self.waterUsageLast14Days.val + dailyUsage;
              self.waterUsageLast30Days.val =
                self.waterUsageLast30Days.val + dailyUsage;
              days++;
            } else if (days < 30 && dailyUsage > 0 && precip < 1) {
              self.waterUsageLast30Days.val =
                self.waterUsageLast30Days.val + dailyUsage;
              days++;
            }
          } else {
            tempIndex++;
          }
        } else {
          currentDateIndex++;
        }
      }
    }

    if (days < 1) {
      self.waterUsageLast7Days.val = 0;
      self.waterUsageLast14Days.val = 0;
      self.waterUsageLast30Days.val = 0;
    } else if (days < 7) {
      self.waterUsageLast7Days.val = Math.round(
        self.waterUsageLast7Days.val / days
      );
      self.waterUsageLast14Days.val = Math.round(
        self.waterUsageLast14Days.val / days
      );
      self.waterUsageLast30Days.val = Math.round(
        self.waterUsageLast30Days.val / days
      );
    } else if (days < 14) {
      self.waterUsageLast7Days.val = Math.round(
        self.waterUsageLast7Days.val / 7
      );
      self.waterUsageLast14Days.val = Math.round(
        self.waterUsageLast14Days.val / days
      );
      self.waterUsageLast30Days.val = Math.round(
        self.waterUsageLast30Days.val / days
      );
    } else if (days < 30) {
      self.waterUsageLast7Days.val = Math.round(
        self.waterUsageLast7Days.val / 7
      );
      self.waterUsageLast14Days.val = Math.round(
        self.waterUsageLast14Days.val / 14
      );
      self.waterUsageLast30Days.val = Math.round(
        self.waterUsageLast30Days.val / days
      );
    } else {
      self.waterUsageLast7Days.val = Math.round(
        self.waterUsageLast7Days.val / 7
      );
      self.waterUsageLast14Days.val = Math.round(
        self.waterUsageLast14Days.val / 14
      );
      self.waterUsageLast30Days.val = Math.round(
        self.waterUsageLast30Days.val / 30
      );
    }

    var max = self.dailyUsage.val;

    if (self.waterUsageLast7Days.val > max) {
      max = self.waterUsageLast7Days.val;
    }

    if (self.waterUsageLast14Days.val > max) {
      max = self.waterUsageLast14Days.val;
    }

    if (self.waterUsageLast30Days.val > max) {
      max = self.waterUsageLast30Days.val;
    }

    if (this.avgEst === "avg") {
      self.waterDays = self.currentVolume / self.waterUsageLast14Days.val;
      self.waterDays = Math.round(self.waterDays);

      if (self.waterDays > 60) {
        self.waterDays = "60+";
      }
    }

    self.dailyUsage.percent = (self.dailyUsage.val / max) * 100;
    self.waterUsageLast7Days.percent =
      (self.waterUsageLast7Days.val / max) * 100;
    self.waterUsageLast14Days.percent =
      (self.waterUsageLast14Days.val / max) * 100;
    self.waterUsageLast30Days.percent =
      (self.waterUsageLast30Days.val / max) * 100;
  }

  waterUsageGraph(count) {
    var self = this;
    self.graphRecordsWU = count;
    if (count > 0) {
      this.custom = "";
    }

    self.calculateTotal(count);
    this.tankChange();
  }

  calculateCurrentVolume() {
    var self = this;
    var length = self.deviceData.length;
    self.waterLevel = self.deviceData[length - 1].data;
    self.waterLevel = Number(self.waterLevel);

    if (this.deviceData.length > 1) {
      self.yesterdayWaterLevel = self.deviceData[length - 2].data;
      self.yesterdayWaterLevel = Number(self.yesterdayWaterLevel);
    }

    //Last-Reading Calculations//
    var lastReading = self.deviceData[length - 1].published_at;

    var mydate = new Date(lastReading);
    var month = mydate.getMonth() + 1;
    var day = mydate.getDate();
    var year = mydate.getUTCFullYear();
    var hours = mydate.getHours();
    var min = mydate.getMinutes();
    self.lastReadingStr =
      year + "/" + month + "/" + day + " " + " " + hours + ":" + min;

    self.dailUsageTodayDate =
      self.monthNames[mydate.getDay()] + " " + day + "/" + month;
    //-----------------//

    //This is the Old One bro..
    //self.currentVolume = (self.waterLevel * 1000) - (self.outletHeight * self.tankArea);

    self.currentVolume =
      (self.waterLevel * 1000 - self.outletHeight) * self.tankArea;

    self.currentVolume = Math.round(self.currentVolume);

    if (this.avgEst === "est") {
      self.waterDays = self.currentVolume / self.estimatedAvgUsage;
      self.waterDays = Math.round(self.waterDays);

      if (self.waterDays > 60) {
        self.waterDays = "60+";
      }
    }
    self.percentVolume = (self.currentVolume / self.maxVolume) * 100;
    self.percentVolume = Math.round(self.percentVolume);
    self.gaugeValue = self.percentVolume;
    if (this.deviceData.length > 1) {
      self.yesterdayVolume =
        (self.yesterdayWaterLevel * 1000 - self.outletHeight) * self.tankArea;
      self.dailyUsage.val = self.yesterdayVolume - self.currentVolume;
      self.dailyUsage.val = Math.round(self.dailyUsage.val);
    }
  }

  getForecast() {
    const url =
      this.forecastWeatherUrl +
      "?key=" +
      this.weatherbitKey +
      "&days=3" +
      "&lat=" +
      this.Beachlands.lat +
      "&lon=" +
      this.Beachlands.lon;

    this.forecastData = [];

    this.http
      .get(url)
      .toPromise()
      .then((response: any) => {
        for (var i = 0; i < response.data.length; i++) {
          var date = new Date(response.data[i].datetime);
          var temp = {
            precip: response.data[i].precip,
            icon: response.data[i].weather.icon + ".png",
            day: this.days[date.getDay()],
            pop: response.data[i].pop,
          };

          this.forecastData.push(temp);
        }
      })
      .catch((err) => {
        console.error(JSON.stringify(err));
      });
  }

  public chartClicked(e: any): void {}

  public chartHovered(e: any): void {}

  public randomize(): void {
    var self = this;
    // Only Change 3 values
    let data = [
      Math.round(Math.random() * 100),
      59,
      80,
      Math.random() * 100,
      56,
      Math.random() * 100,
      40,
    ];
    let clone = JSON.parse(JSON.stringify(self.barChartData));
    clone[0].data = data;
    self.barChartData = clone;
    /**
     * (My guess), for Angular to recognize the change in the dataset
     * it has to change the dataset variable directly,
     * so one way around it, is to clone the data, change it and then
     * assign it;
     */
  }

  toFindData() {
    if (this.custom == "water") {
      var dates = this.getAllDates(
        new Date(this.startDate),
        new Date(this.endDate)
      );
      var allDates = [];
      var allUsage = [];
      this.graphRecordsWU = 0;
      dates.forEach((item) => {
        var index = this.allDates.findIndex((x) => x === item);
        if (index >= 0) {
          allDates.push(this.allDates[index]);
          allUsage[index] = Number(this.allUsage[index].toFixed(1));
          allUsage.push(this.allUsage[index]);
        }
      });
      this.zone.run(() => {
        setTimeout(() => (this.barChartLabelsW = allDates), 0);
        this.barChartDataW = [{ data: allUsage, label: "Water Usage" }];
        this.barChartDataW[0].label = "Water Usage (Litres)";
      });
    } else if (this.custom == "level") {
      var dates = this.getfullAllDates(
        new Date(this.startDate),
        new Date(this.endDate)
      );
      this.barChartLabelsFullDate = [];
      this.barChartLabels = [];
      this.barChartData = [
        {
          data: [],
          label: "Water Level",
          type: "line",
          fill: "origin",
        },
      ];

      this.graphRecords = 0;

      for (var i = 0; i < this.tankDates.length; i++) {
        if (dates.includes(this.tankDates[i])) {
          this.barChartLabels.push(this.tankDatesShort[i]);
          this.barChartLabelsFullDate.push(this.tankfullDates[i]);
          this.barChartData[0].data.push(this.tankData[i]);
        }
      }
      var waterLevelFirstReading = Number(this.barChartData[0].data[0]);
      var waterLevelLastReading = Number(
        this.barChartData[0].data[this.barChartData[0].data.length - 1]
      );
      var voulmeFirstReading =
        (waterLevelFirstReading * 1000 - this.outletHeight) * this.tankArea;
      var voulmeLastReading =
        (waterLevelLastReading * 1000 - this.outletHeight) * this.tankArea;
      this.currentTank.waterStats.currentVolume = Math.round(
        voulmeLastReading - voulmeFirstReading
      );
      this.barChartData[0].label = "Water Level";
      this.barChartData[0].data = this.barChartData[0].data.reverse();
      this.barChartLabels = this.barChartLabels.reverse();
    }
  }

  getAllDates(startDate, endDate) {
    var dates = [],
      currentDate = startDate,
      addDays = function (days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
      };
    while (currentDate <= endDate) {
      dates.push(moment(currentDate).format("D/M"));

      currentDate = addDays.call(currentDate, 1);
    }
    return dates;
  }

  getfullAllDates(startDate, endDate) {
    var dates = [],
      currentDate = startDate,
      addDays = function (days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
      };
    while (currentDate <= endDate) {
      dates.push(moment(currentDate).format("D/M/YYYY"));
      currentDate = addDays.call(currentDate, 1);
    }
    return dates;
  }

  getAllData() {
    var promises = [];
    this.currentTankWater = "All Tanks";
    this.allTanks.forEach((tank) => {
      var p = this.getReadingOfSpecificDevice(tank.deviceId).then(
        async (data) => {
          if (tank.deviceId == this.currentTank.deviceId) {
            this.currentTank.deviceData = data.deviceData || [];
            this.currentTank.allDeviceData = data.allDeviceData || [];
            await this.calculateCurrentVolumeOfTank(this.currentTank);
          }
          tank.deviceData = data.deviceData || [];
          tank.allDeviceData = data.allDeviceData || [];
          await this.calculateCurrentVolumeOfTank(tank);
          await this.waterUsageOfTank(tank);
          return true;
        }
      );
      promises.push(p);
    });

    Promise.all(promises).then((res) => {
      this.getTotalOfAll();
      this.waterUsage();
      this.getDeviceData();
      if (this.custom == "water") {
        this.waterUsageGraph(0);
        this.calculateTotal(0);
      } else if (this.custom == "level") {
        this.calculateTotal(0);
        this.waterUsageGraph(0);
      } else {
        this.calculateTotal(this.graphRecordsWU);
        this.waterUsageGraph(this.graphRecordsWU);
      }

      //this.getDeviceData();
      this.loading = false;
    });
  }

  getTotalOfAll() {
    this.allDates = [];
    this.allUsage = [];
    this.allTanks.forEach((tank) => {
      const a = tank;
      tank.waterUsageDateArray = tank.waterUsageDateArray.splice(0, 300);
      tank.waterUsageArray = tank.waterUsageArray.splice(0, 300);

      for (var i = 0; i < tank.waterUsageDateArray.length; i++) {
        if (this.allDates.indexOf(tank.waterUsageDateArray[i]) >= 0) {
          this.allUsage[this.allDates.indexOf(tank.waterUsageDateArray[i])] +=
            tank.waterUsageArray[i];
        } else {
          this.allDates.push(tank.waterUsageDateArray[i]);
          tank.waterUsageArray[i] = Number(tank.waterUsageArray[i].toFixed(1));
          this.allUsage.push(tank.waterUsageArray[i]);
        }
      }
    });
  }

  getReadingOfSpecificDevice(deviceId): Promise<any> {
    return firebase
      .database()
      .ref()
      .child(`deviceData/${deviceId}`)
      .once("value")
      .then((snapshot) => {
        var deviceData = [];
        var allDeviceData = [];
        var userDevice = snapshot.val();

        for (var data in userDevice) {
          var temp = userDevice[data];
          if (temp.data.indexOf("waterLevel") >= 0) {
            continue;
          }
          allDeviceData.push(temp);
          if (deviceData.length > 0) {
            var currentDate = new Date(temp.published_at);
            var lastDate = new Date(
              deviceData[deviceData.length - 1].published_at
            );

            if (currentDate.getDate() === lastDate.getDate()) {
              deviceData[deviceData.length - 1] = temp;
            } else {
              deviceData.push(temp);
            }
          } else {
            deviceData.push(temp);
          }
        }

        var allData: any = {
          deviceData: deviceData,
          allDeviceData: allDeviceData,
        };

        return allData;
      });
  }

  async calculateCurrentVolumeOfTank(tank) {
    var length = tank.deviceData.length;
    tank.waterStats = {
      dailyUsage: {
        val: 0,
        percent: 0,
      },
    };

    if (length < 1) {
      tank.waterStats.waterLevel = 0;
      tank.waterStats.yesterdayWaterLevel = 0;
      tank.waterStats.currentVolume = 0;
      tank.waterStats.waterDays = 0;
      tank.waterStats.percentVolume = 0;
      tank.waterStats.yesterdayVolume = 0;
      return;
    }

    tank.waterStats.waterLevel = tank.deviceData[length - 1].data;
    tank.waterStats.waterLevel = Number(tank.waterStats.waterLevel);
    if (length > 1) {
      tank.waterStats.yesterdayWaterLevel = tank.deviceData[length - 2].data;
      tank.waterStats.yesterdayWaterLevel = Number(
        tank.waterStats.yesterdayWaterLevel
      );
    } else {
      tank.waterStats.yesterdayWaterLevel = tank.waterStats.waterLevel;
    }

    //Last-Reading Calculations//

    if (length > 1) {
      var lastReading = tank.deviceData[length - 1].published_at;
      var lastReadingBefore = tank.deviceData[length - 2].published_at;
    } else if (length === 1) {
      var lastReading = tank.deviceData[length - 1].published_at;
      var lastReadingBefore = tank.deviceData[length - 1].published_at;
    } else {
      return;
    }

    //var lastReading = tank.deviceData[length - 1].published_at;

    var mydate = new Date(lastReading);
    var todayDate = new Date(lastReadingBefore);

    tank.waterStats.lastReadingStr = moment(mydate).format(
      "ddd D/MM [at] HH:mm"
    );
    if (!this.lastReadingStr) {
      this.lastReadingStr = tank.waterStats.lastReadingStr;
    }

    tank.waterStats.dailUsageTodayDate = moment(todayDate).format("ddd D/MM");
    if (!this.dailUsageTodayDate) {
      this.dailUsageTodayDate = tank.waterStats.dailUsageTodayDate;
    }
    // this.dailUsageTodayDate = moment(mydate).format("ddd D/MM");
    //this.dailUsageTodayDate = this.monthNames[mydate.getDay()] + " " + day + "/" + month;
    //-----------------//

    //This is the Old One bro..
    //this.currentVolume = (this.waterLevel * 1000) - (this.outletHeight * this.tankArea);

    tank.waterStats.currentVolume =
      (tank.waterStats.waterLevel * 1000 - tank.outletHeight) * tank.tankArea;

    tank.waterStats.currentVolume = Math.round(tank.waterStats.currentVolume);

    /* if (tank.avgEst === "est") {
      tank.waterStats.waterDays = tank.waterStats.currentVolume / tank.estimatedAvgUsage;
      tank.waterStats.waterDays = Math.round(tank.waterStats.waterDays);

      if (tank.waterStats.waterDays > 60) {
        tank.waterStats.waterDays = "60+"
      }
    } */

    tank.waterStats.percentVolume =
      (tank.waterStats.currentVolume / tank.maxVolume) * 100;
    tank.waterStats.percentVolume = Math.round(tank.waterStats.percentVolume);

    tank.waterStats.yesterdayVolume =
      (tank.waterStats.yesterdayWaterLevel * 1000 - tank.outletHeight) *
      tank.tankArea;
    tank.waterStats.dailyUsage.val =
      tank.waterStats.yesterdayVolume - tank.waterStats.currentVolume;
    tank.waterStats.dailyUsage.val = Math.round(tank.waterStats.dailyUsage.val);

    tank.waterStats.waterDays =
      tank.waterStats.currentVolume /
      (tank.waterStats.yesterdayVolume - tank.waterStats.currentVolume);
    tank.waterStats.waterDays = Math.round(tank.waterStats.waterDays);

    if (tank.waterStats.waterDays > 60) {
      tank.waterStats.waterDays = "60+";
    }
  }

  async waterUsageOfTank(tank) {
    var length = tank.deviceData.length;

    tank.waterUsageArray = [];
    tank.waterUsageDateArray = [];

    tank.waterStats.waterUsageLast14Days = {
      val: 0,
      percent: 0,
    };
    tank.waterStats.waterUsageLast7Days = {
      val: 0,
      percent: 0,
    };
    tank.waterStats.waterUsageLast30Days = {
      val: 0,
      percent: 0,
    };

    var index = 0;
    var nowIndex = 0;
    var tempIndex = 0;
    var currentDateIndex = 0;
    var days = 0;

    for (
      var i = 0;
      i < tank.deviceData.length - 1 &&
      index < tank.deviceData.length &&
      tank.waterUsageArray.length < tank.deviceData.length;
      i++
    ) {
      var waterLevel = 0;
      var yesterdayVolume = 0;
      var currentVolume = 0;
      var yesterdayWaterLevel = 0;
      var dailyUsage = 0;

      var date = new Date();
      var yesterday = new Date(date.getTime());
      yesterday.setDate(date.getDate() - (i + 1));

      var now = new Date(date.getTime());

      now.setDate(date.getDate() - (currentDateIndex + nowIndex));
      var sampleDate = now.getDate() + "/" + (now.getMonth() + 1);

      if (index < tank.deviceData.length - 1) {
        var currentreadingDate = new Date(
          tank.deviceData[length - (index + 1)].published_at
        );
        var yesterdayReadingDate = new Date(
          tank.deviceData[length - (index + 2)].published_at
        );

        if (
          now.toLocaleDateString() === currentreadingDate.toLocaleDateString()
        ) {
          if (
            now.toLocaleDateString() ===
              currentreadingDate.toLocaleDateString() &&
            yesterday.toLocaleDateString() ===
              yesterdayReadingDate.toLocaleDateString()
          ) {
            yesterdayWaterLevel = tank.deviceData[length - (index + 2)].data;
            yesterdayWaterLevel = Number(yesterdayWaterLevel);
            waterLevel = Number(tank.deviceData[length - (index + 1)].data);
            currentVolume =
              (waterLevel * 1000 - tank.outletHeight) * tank.tankArea;
            yesterdayVolume =
              (yesterdayWaterLevel * 1000 - tank.outletHeight) * tank.tankArea;
            dailyUsage = yesterdayVolume - currentVolume;
            index++;
            nowIndex += tempIndex;
            currentDateIndex++;
            tempIndex = 0;
            tank.waterUsageDateArray.push(sampleDate.toString());
            tank.waterUsageArray.push(dailyUsage);

            if (days < 7 && dailyUsage > 0) {
              tank.waterStats.waterUsageLast7Days.val =
                tank.waterStats.waterUsageLast7Days.val + dailyUsage;
              tank.waterStats.waterUsageLast14Days.val =
                tank.waterStats.waterUsageLast14Days.val + dailyUsage;
              tank.waterStats.waterUsageLast30Days.val =
                tank.waterStats.waterUsageLast30Days.val + dailyUsage;
              days++;
            } else if (days < 14 && dailyUsage > 0) {
              tank.waterStats.waterUsageLast14Days.val =
                tank.waterStats.waterUsageLast14Days.val + dailyUsage;
              tank.waterStats.waterUsageLast30Days.val =
                tank.waterStats.waterUsageLast30Days.val + dailyUsage;
              days++;
            } else if (days < 30 && dailyUsage > 0) {
              tank.waterStats.waterUsageLast30Days.val =
                tank.waterStats.waterUsageLast30Days.val + dailyUsage;
              days++;
            }
          } else {
            tempIndex++;
          }
        } else {
          currentDateIndex++;
        }
      }
    }

    if (days < 1) {
      tank.waterStats.waterUsageLast7Days.val = 0;
      tank.waterStats.waterUsageLast14Days.val = 0;
      tank.waterStats.waterUsageLast30Days.val = 0;
    } else if (days < 7) {
      tank.waterStats.waterUsageLast7Days.val = Math.round(
        tank.waterStats.waterUsageLast7Days.val / days
      );
      tank.waterStats.waterUsageLast14Days.val = Math.round(
        tank.waterStats.waterUsageLast14Days.val / days
      );
      tank.waterStats.waterUsageLast30Days.val = Math.round(
        tank.waterStats.waterUsageLast30Days.val / days
      );
    } else if (days < 14) {
      tank.waterStats.waterUsageLast7Days.val = Math.round(
        tank.waterStats.waterUsageLast7Days.val / 7
      );
      tank.waterStats.waterUsageLast14Days.val = Math.round(
        tank.waterStats.waterUsageLast14Days.val / days
      );
      tank.waterStats.waterUsageLast30Days.val = Math.round(
        tank.waterStats.waterUsageLast30Days.val / days
      );
    } else if (days < 30) {
      tank.waterStats.waterUsageLast7Days.val = Math.round(
        tank.waterStats.waterUsageLast7Days.val / 7
      );
      tank.waterStats.waterUsageLast14Days.val = Math.round(
        tank.waterStats.waterUsageLast14Days.val / 14
      );
      tank.waterStats.waterUsageLast30Days.val = Math.round(
        tank.waterStats.waterUsageLast30Days.val / days
      );
    } else {
      tank.waterStats.waterUsageLast7Days.val = Math.round(
        tank.waterStats.waterUsageLast7Days.val / 7
      );
      tank.waterStats.waterUsageLast14Days.val = Math.round(
        tank.waterStats.waterUsageLast14Days.val / 14
      );
      tank.waterStats.waterUsageLast30Days.val = Math.round(
        tank.waterStats.waterUsageLast30Days.val / 30
      );
    }

    tank.waterStats.waterDays =
      tank.waterStats.currentVolume / tank.waterStats.waterUsageLast14Days.val;
    tank.waterStats.waterDays = Math.round(tank.waterStats.waterDays);

    if (tank.waterStats.waterDays > 60) {
      tank.waterStats.waterDays = "60+";
    }

    var max = tank.waterStats.dailyUsage.val;

    if (tank.waterStats.waterUsageLast14Days.val > max) {
      max = tank.waterStats.waterUsageLast14Days.val;
    }

    tank.waterStats.dailyUsage.percent =
      (tank.waterStats.dailyUsage.val / max) * 100;
    tank.waterStats.waterUsageLast14Days.percent =
      (tank.waterStats.waterUsageLast14Days.val / max) * 100;
  }
  getDeviceData() {
    this.currentTank = JSON.parse(JSON.stringify(this.selectedTank));
    this.getDeviceConfiguration(this.currentTank);
    this.getReadingOfSpecificDevice(this.currentTank.deviceId).then((data) => {
      this.deviceData = data.allDeviceData || [];
      this.getDeviceDataTank();
      if (this.custom) {
        this.waterLevelChartData(0);
      } else {
        this.waterLevelChartData(this.graphRecords);
      }

      if (this.deviceData.length) {
        this.calculateCurrentVolume();
      }
    });
  }

  getDeviceDataWater() {
    if (this.currentTankWater != "All Tanks") {
      if (this.currentTankWater.deviceData.length) {
        this.waterUsageOfTank(this.currentTankWater);
      }
      this.allDates = [];
      this.allUsage = [];
      this.getData(0);
    } else {
      this.allDates = [];
      this.allUsage = [];
      this.getAllData();
    }
    this.tankChange();
  }
  
  tankChange() {
    const calculateAvg = (barChartData: string | any[], days: number) => {
      if (barChartData && barChartData.length > 0 && barChartData[0].data) {
        const dataArray = barChartData[0].data;
        if (dataArray.length > 0) {
          const totalSum = dataArray.reduce(
            (acc: any, currentValue: any) => acc + currentValue,
            0
          );
          this.avgCalculation = totalSum / days;
        } else {
          console.error("The 'data' array is empty.");
        }
      } else {
        console.error(
          `Invalid barChartDataWU${days} structure. Make sure the array and 'data' array exist.`
        );
      }
    };

    if (this.graphRecordsWU === 7) {
      calculateAvg(this.barChartDataWU7, 7);
    } else if (this.graphRecordsWU === 14) {
      calculateAvg(this.barChartDataWU14, 14);
    } else if (this.graphRecordsWU === 30) {
      calculateAvg(this.barChartDataWU30, 30);
    }
  }

  getData(i: number) {
    if (i >= this.currentTankWater.waterUsageDateArray.length) {
      this.waterUsage();
      if (this.custom == "water") {
        this.waterUsageGraph(0);
        this.calculateTotal(0);
      } else if (this.custom == "level") {
        this.calculateTotal(0);
        this.waterUsageGraph(0);
      } else {
        this.calculateTotal(this.graphRecordsWU);
        this.waterUsageGraph(this.graphRecordsWU);
      }

      this.getDeviceData();
    } else {
      if (
        this.allDates.indexOf(this.currentTankWater.waterUsageDateArray[i]) >= 0
      ) {
        this.allUsage[
          this.allDates.indexOf(this.currentTankWater.waterUsageDateArray[i])
        ] += this.currentTankWater.waterUsageArray[i];
      } else {
        this.allDates.push(this.currentTankWater.waterUsageDateArray[i]);
        this.currentTankWater.waterUsageArray[i] = Number(
          this.currentTankWater.waterUsageArray[i].toFixed(1)
        );
        this.allUsage.push(this.currentTankWater.waterUsageArray[i]);
      }
      this.getData(i + 1);
    }
  }

  calculateTotal(count: number) {
    this.barChartDataWU7 = [];
    this.barChartLabelsWU7 = [];

    this.total = {
      currentVolume: 0,
      maxVolume: 0,
      percentVolume: 0,
      waterDays: 0,
      avgUsage: 0,
      dailyUsage: 0,
    };
    var totalDaily = 0,
      totalAvg = 0;
    this.allTanks.forEach((tank) => {
      this.total.currentVolume += tank.waterStats.currentVolume;
      this.total.maxVolume += tank.maxVolume;
      totalDaily += tank.waterStats.dailyUsage.val;
      if (this.allUsage.length) {
        if (count == 7) {
          if (this.usage) {
            this.zone.run(() => {
              setTimeout(
                () =>
                  (this.barChartLabelsWU7 = this.allDates
                    .slice(0, count)
                    .reverse()),
                0
              );
              this.barChartDataWU7 = [
                {
                  data: this.allUsage.slice(0, count).reverse(),
                  label: "Water Usage",
                },
              ];
              totalAvg += tank.waterStats.waterUsageLast7Days.val;
              this.barChartDataWU7[0].label = "Water Usage (Litres)";
              this.graphRecordsWU = count;
            });
          } else {
            var waterUsage = tank.waterUsageArray;
            var dateUsage = tank.waterUsageDateArray;
            tank.waterUsageArray = [];
            tank.waterUsageDateArray = [];

            var tempDates = this.allDates;
            var tempUsage = this.allUsage;

            this.allUsage = [];
            this.allDates = [];

            for (var i = 0; i < tempUsage.length; i++) {
              if (tempUsage[i] > 0) {
                tempUsage[i] = Number(tempUsage[i].toFixed(1));
                this.allUsage.push(tempUsage[i]);
                this.allDates.push(tempDates[i]);
              }
            }
            this.zone.run(() => {
              this.barChartDataWU7 = [
                {
                  data: this.allUsage.slice(0, count).reverse(),
                  label: "Water Usage",
                },
              ];
              setTimeout(
                () =>
                  (this.barChartLabelsWU7 = this.allDates
                    .slice(0, count)
                    .reverse()),
                0
              );
              totalAvg += tank.waterStats.waterUsageLast7Days.val;
              this.barChartDataWU7[0].label = "Water Usage (Litres)";
              this.graphRecordsWU = count;
            });
          }
        } else if (count == 14) {
          if (this.usage) {
            this.zone.run(() => {
              setTimeout(
                () =>
                  (this.barChartLabelsWU14 = this.allDates
                    .slice(0, count)
                    .reverse()),
                0
              );
              this.barChartDataWU14 = [
                {
                  data: this.allUsage.slice(0, count).reverse(),
                  label: "Water Usage",
                },
              ];
              totalAvg += tank.waterStats.waterUsageLast14Days.val;
              this.barChartDataWU14[0].label = "Water Usage (Litres)";
              this.graphRecordsWU = count;
            });
          } else {
            var waterUsage = tank.waterUsageArray;
            var dateUsage = tank.waterUsageDateArray;
            tank.waterUsageArray = [];
            tank.waterUsageDateArray = [];
            for (var i = 0; i < waterUsage.length; i++) {
              if (waterUsage[i] > 0) {
                tank.waterUsageArray.push(waterUsage[i]);
                tank.waterUsageDateArray.push(dateUsage[i]);
              }
            }

            var tempDates = this.allDates;
            var tempUsage = this.allUsage;

            this.allUsage = [];
            this.allDates = [];

            for (var i = 0; i < tempUsage.length; i++) {
              if (tempUsage[i] > 0) {
                tempUsage[i] = Number(tempUsage[i].toFixed(1));
                this.allUsage.push(tempUsage[i]);
                this.allDates.push(tempDates[i]);
              }
            }

            this.zone.run(() => {
              this.barChartDataWU14 = [
                {
                  data: this.allUsage.slice(0, count).reverse(),
                  label: "Water Usage",
                },
              ];
              setTimeout(
                () =>
                  (this.barChartLabelsWU14 = this.allDates
                    .slice(0, count)
                    .reverse()),
                0
              );
              totalAvg += tank.waterStats.waterUsageLast14Days.val;
              this.barChartDataWU14[0].label = "Water Usage (Litres)";
              this.graphRecordsWU = count;
            });
          }
        } else if (count == 30) {
          if (this.usage) {
            this.zone.run(() => {
              setTimeout(
                () =>
                  (this.barChartLabelsWU30 = this.allDates
                    .slice(0, count)
                    .reverse()),
                0
              );
              this.barChartDataWU30 = [
                {
                  data: this.allUsage.slice(0, count).reverse(),
                  label: "Water Usage",
                },
              ];
              totalAvg += tank.waterStats.waterUsageLast30Days.val;
              this.barChartDataWU30[0].label = "Water Usage (Litres)";
              this.graphRecordsWU = count;
            });
          } else {
            var waterUsage = tank.waterUsageArray;
            var dateUsage = tank.waterUsageDateArray;
            tank.waterUsageArray = [];
            tank.waterUsageDateArray = [];
            for (var i = 0; i < waterUsage.length; i++) {
              if (waterUsage[i] > 0) {
                tank.waterUsageArray.push(waterUsage[i]);
                tank.waterUsageDateArray.push(dateUsage[i]);
              }
            }

            var tempDates = this.allDates;
            var tempUsage = this.allUsage;

            this.allUsage = [];
            this.allDates = [];

            for (var i = 0; i < tempUsage.length; i++) {
              if (tempUsage[i] > 0) {
                tempUsage[i] = Number(tempUsage[i].toFixed(1));
                this.allUsage.push(tempUsage[i]);
                this.allDates.push(tempDates[i]);
              }
            }

            this.zone.run(() => {
              this.barChartDataWU30 = [
                {
                  data: this.allUsage.slice(0, count).reverse(),
                  label: "Water Usage",
                },
              ];
              setTimeout(
                () =>
                  (this.barChartLabelsWU30 = this.allDates
                    .slice(0, count)
                    .reverse()),
                0
              );
              totalAvg += tank.waterStats.waterUsageLast30Days.val;
              this.barChartDataWU30[0].label = "Water Usage (Litres)";
              this.graphRecordsWU = count;
            });
          }
        } else if (count == 0) {
          if (this.usage) {
          } else {
            var waterUsage = tank.waterUsageArray;
            var dateUsage = tank.waterUsageDateArray;
            tank.waterUsageArray = [];
            tank.waterUsageDateArray = [];
            for (var i = 0; i < waterUsage.length; i++) {
              if (waterUsage[i] > 0) {
                tank.waterUsageArray.push(waterUsage[i]);
                tank.waterUsageDateArray.push(dateUsage[i]);
              }
            }

            var tempDates = this.allDates;
            var tempUsage = this.allUsage;

            this.allUsage = [];
            this.allDates = [];

            for (var i = 0; i < tempUsage.length; i++) {
              if (tempUsage[i] > 0) {
                tempUsage[i] = Number(tempUsage[i].toFixed(1));
                this.allUsage.push(tempUsage[i]);
                this.allDates.push(tempDates[i]);
              }
            }
          }
        }
        this.toFindData();
      }
    });

    this.dailUsageTodayDate = moment(this.allDates[0], "D/M").format(
      "ddd D/MM"
    );

    this.total.maxVolume = Math.round(this.total.maxVolume);
    this.total.avgUsage = Math.round(totalAvg) / this.allTanks.length;
    this.total.dailyUsage = Math.round(totalDaily);

    if (this.total.avgUsage > this.total.dailyUsage) {
      this.total.avgUsagePercent = 100;
      this.total.dailyUsagePercent =
        (this.total.dailyUsage / this.total.avgUsage) * 100;

      if (this.total.dailyUsage < 0) {
        this.total.dailyUsagePercent = 10;
      }
    } else {
      this.total.dailyUsagePercent = 100;
      this.total.avgUsagePercent =
        (this.total.avgUsage / this.total.dailyUsage) * 100;

      if (this.total.avgUsage < 0) {
        this.total.avgUsagePercent = 10;
      }
    }

    this.total.waterDays = Math.round(
      this.total.currentVolume / this.total.avgUsage
    );

    if (this.total.waterDays > 60) {
      this.total.waterDays = "60+";
    }

    this.total.percentVolume = Math.round(
      (this.total.currentVolume / this.total.maxVolume) * 100
    );
  }
}

import { Component, OnInit } from '@angular/core';
import { format } from 'url';
import * as firebase from 'firebase';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../data.service';

@Component({
  selector: 'app-complete-profile',
  templateUrl: './complete-profile.component.html',
  styleUrls: ['./complete-profile.component.css']
})
export class CompleteProfileComponent implements OnInit {

  public state: any;
  public country: any;
  public postalCode: any;
  public mobileNumber: any;
  public userLat: any;
  public userLng: any;
  public userAddress: any;
  public user: any;
  public loading: boolean= false;
  public oldPassword: any;
  public newPassword: any;
  public confirmPassword: any;
  public token: string;

  email: any;
  displayName: any;

  managerCode: string = "";

  public locationSettings: any = {
    inputPlaceholderText: "Street Address",
  };

  constructor(public router: Router, public service: DataService) {
    this.service.activeTab = 'accounts';
  }

  ngOnInit() {
    this.getUserData();

  }

  getUserData() {
    this.loading = true;
 
    const fetchUserData = (): Promise<void> => {
      return new Promise<void>((resolve, reject) => {
        firebase.database().ref('users/').once('value', (snapshot) => {
          const users = snapshot.val();
          for (const key in users) {
            if (key === localStorage.getItem('currUserKey')) {
              this.user = users[key];
              console.log(this.user);
              resolve();
            }
          }
          reject(new Error('User not found')); // Reject if user not found
        });
      });
    };
 
    const fetchUserApiToken = (): Promise<void> => {
      return new Promise<void>((resolve, reject) => {
        firebase
          .database()
          .ref('/apiKeys')
          .orderByChild('uid')
          .equalTo(this.user.uid)
          .once('value')
          .then((snapshot) => {
            const data = Object.keys(snapshot.val())[0];
            this.token = data;
            console.log('Token', this.token);
            resolve();
          })
          .catch((error) => reject(error));
      });
    };
 
    // Fetch user data and then fetch API token
    fetchUserData()
      .then(() => fetchUserApiToken())
      .then(() => {
        this.loading = false;
      })
      .catch((error) => {
        console.error('Error:', error);
        this.loading = false; // Handle error and set loading to false
      });
  }
  toSaveName() {
     this.loading = true;
    var updates = {};
    var displayName: any;
    if (this.user.displayName) {
      displayName = this.user.displayName;
    }
    else {
      displayName = this.displayName;
      this.user.displayName = this.displayName;
    }
    updates['users/' + localStorage.getItem('currUserKey') + '/displayName/'] = displayName;
    firebase.database().ref().update(updates).then(() => {

     this.loading = false;
    })
  }

  toSaveEmail() {
     this.loading = true;
    var user = firebase.auth().currentUser;

    var email: any;
    if (this.user.email) {
      email = this.user.email;
    }
    else {
      this.user.email = this.email;
      email = this.email;
    }
    user.updateEmail(this.user.email).then(() => {

      var updates = {};
      updates['users/' + localStorage.getItem('currUserKey') + '/email/'] = email;
      firebase.database().ref().update(updates).then(() => {

         this.loading = false;
      })

    }).catch((error) => {
      this.loading = false;
      console.log(error);
      // An error happened.
    });

  }

  toChangePassword() {

    this.loading = true;
    firebase.auth().signInWithEmailAndPassword(this.user.email, this.oldPassword)
      .then((user) => {
        if (user) {

          firebase.auth().currentUser.updatePassword(this.newPassword).then(() => {
            this.newPassword = null;
            this.oldPassword = null;
            this.confirmPassword = null;
            localStorage.clear();
            this.router.navigate(['/login']);
            this.service.activeTab = 'dashboard';
            this.loading = false;
          })
            .catch((e) => {
              this.loading = false;
            });
        }
      })
      .catch((e) => {
        this.loading = false;
      });

  }

  autoCompleteCallback1(selectedData: any) {

    var self = this;
    var Data = selectedData.data;
    self.user.userAddress = Data.description;
    var geometry = Data.geometry;
    var Location = geometry.location;
    self.userLat = Location.lat;
    self.userLng = Location.lng;

    //For Postal Code..
    var tempArray = Data.address_components;

    for (var i = 0; i < tempArray.length; i++) {
      var typesArray = tempArray[i].types;

      for (var j = 0; j < typesArray.length + 1; j++) {
        if (typesArray[j] == "postal_code") {

          self.user.postalCode = tempArray[i].long_name;
        } else if (typesArray[j] == "sublocality_level_1") {
          self.user.state = tempArray[i].long_name;
        } else if (typesArray[j] == "country") {

          self.user.country = tempArray[i].long_name;
        }
      }
    }
  }

  proceede() {
    this.updateUser();
  }

  updateUser() {


    var self = this;

    if (self.user.postalCode || self.user.state || self.user.country) {
      self.loading = true;
      firebase.database().ref().child('users')
        .once('value', function (snapshot) {
          var users = snapshot.val();
          for (var key in users) {
            var user = users[key];
            if (user.uid == localStorage.getItem("uid")) {

              user.state = self.user.state ? self.user.state : "";
              user.country = self.user.country ? self.user.country : "";
              user.postalCode = self.user.postalCode ? self.user.postalCode : "";
              user.mobileNumber = self.mobileNumber ? self.mobileNumber : "";
              user.userLat = self.userLat ? self.userLat : "";
              user.userLng = self.userLng ? self.userLng : "";
              user.userAddress = self.user.userAddress ? self.user.userAddress : "";
              localStorage.setItem("postalCode", user.postalCode);
              localStorage.setItem("mobileNumber", user.mobileNumber);

              var updates = {};

              updates['/users/' + key] = user;
              firebase.database().ref().update(updates).then(() => {
                self.loading = false;
              });
            }
          }
        });
    } else {
      alert("Please select address!");
    }

  }

  checkManagerCode() {
    if (!this.managerCode) {
      return alert("Please enter service provider code!");
    }

    firebase.database().ref("/users")
      .orderByChild("companyCode")
      .equalTo(this.managerCode)
      .once("value")
      .then(snapshot => {
        var data = snapshot.val();

        if (!data) {
          return alert("Service provider not found with this code!");
        }

        var serviceProvider;

        for (var key in data) {
          serviceProvider = data[key];
        }

        var updates = {};
        updates['/users/' + localStorage.getItem("currUserKey") + "/managerCode"] = serviceProvider.companyCode;
        updates['/users/' + localStorage.getItem("currUserKey") + "/managerUid"] = serviceProvider.uid;
        updates['/users/' + localStorage.getItem("currUserKey") + "/managerName"] = serviceProvider.companyName;
        updates['/users/' + localStorage.getItem("currUserKey") + "/managerApproved"] = false;

        this.service.currentUser.managerCode = serviceProvider.companyCode;
        this.service.currentUser.managerUid = serviceProvider.uid;
        this.service.currentUser.managerName = serviceProvider.companyName;
        this.service.currentUser.managerApproved = false;

        localStorage.setItem("userObj", JSON.stringify(this.service.currentUser));

        firebase.database().ref().update(updates);

        var btn = document.getElementById("showConfirmModal");
        btn.click();
      })
  }

  removeManager() {
    var updates = {};
    updates['/users/' + localStorage.getItem("currUserKey") + "/managerCode"] = "";
    updates['/users/' + localStorage.getItem("currUserKey") + "/managerUid"] = "";
    updates['/users/' + localStorage.getItem("currUserKey") + "/managerName"] = "";
    updates['/users/' + localStorage.getItem("currUserKey") + "/managerApproved"] = false;

    delete this.service.currentUser.managerCode;
    delete this.service.currentUser.managerUid;
    delete this.service.currentUser.managerName;
    delete this.service.currentUser.managerApproved;

    localStorage.setItem("userObj", JSON.stringify(this.service.currentUser));

    firebase.database().ref().update(updates);
  }

  generateToken(email: string): string {
    this.loading = true;
    const randomString = Math.random().toString(36).substring(7);
    const timestamp = new Date().getTime();

    const generatedToken = btoa(`${email}${randomString}${timestamp}`);
    this.loading = false;
    
    firebase
    .database()
    .ref(`/apiKeys/${generatedToken}`)
    .set({
      uid: this.user.uid,
      valid : true
    })
    .then((result) => {
      firebase.database().ref(`/apiKeys/${this.token}`).remove();
      console.log("result", result);
      this.loading = false;
      this.token = generatedToken})
    .catch((error) => {
      this.loading = false;
      console.log("error");
    });
return this.token;
  }

  copyToClipboard(token: string): void {
    const el = document.createElement("textarea");
    el.value = token;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  }
}

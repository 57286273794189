import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../data.service';

@Component({
  selector: 'app-signup-step1',
  templateUrl: './signup-step1.component.html',
  styleUrls: ['./signup-step1.component.css']
})
export class SignupStep1Component implements OnInit {
  public activeRole: string = "INDIVIDUAL";

  constructor(public dataService: DataService, public route: Router) { }

  ngOnInit() {
  }

  signup() {
    this.dataService.role = this.activeRole;
    this.route.navigateByUrl("/signup-step2");
  }

}

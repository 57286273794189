import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import * as firebase from 'firebase';
import { DataService } from '../data.service';

@Component({
  selector: 'app-signup-step2',
  templateUrl: './signup-step2.component.html',
  styleUrls: ['./signup-step2.component.css']
})
export class SignupStep2Component implements OnInit {

  public registerForm: FormGroup;
  public provider: any;
  public loading = false;
  public uid: any;
  public email: any;
  public displayName; any;
  public photoURL: any;
  public alreadyExists: any = false;

  constructor(private _fb: FormBuilder,
    public dataService: DataService,
    public router: Router) {
    var x = document.getElementById("myNav");
    x.style.display = "none";
    this.provider = new firebase.auth.GoogleAuthProvider();
  }

  ngOnInit() {
    this.registerForm = this._fb.group({
      displayName: ['', Validators.compose([
        Validators.required
      ])],
      email: ['', Validators.compose([
        Validators.required
      ])],
      password: ['', Validators.compose([
        Validators.required
      ])],
      cPassword: ['', Validators.compose([
        Validators.required
      ])],
    });
  }


  signUp() {
    var self = this;
    self.loading = true;

    if (self.registerForm.get("password").value != self.registerForm.get("cPassword").value) {
      alert("Password and Confirm Password Missmatch");
      self.loading = false;
      return;
    }

    firebase.auth().createUserWithEmailAndPassword(self.registerForm.get("email").value.toLowerCase(), self.registerForm.get("password").value)
      .then(
        (user) => {
          // here you can use either the returned user object or       firebase.auth().currentUser. I will use the returned user object
          if (user) {

            if (firebase.auth().currentUser) {
              self.uid = firebase.auth().currentUser.uid;
              localStorage.setItem("uid", self.uid);
              self.saveUser();

            }
          }
        })
      .catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        alert(errorMessage);
        self.loading = false;
      });
  }


  saveUser() {

    var self = this;
    var postData: any = {
      uid: self.uid,
      email: self.registerForm.get("email").value.toLowerCase(),
      displayName: self.registerForm.get("displayName").value,
      photoURL: "",
      timestamp: Number(new Date()),
    };

    if (this.dataService.role === "MANAGER") {
      postData.isManager = true;

      var result = [];
      var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
      var charactersLength = characters.length;
      for (var i = 0; i < 6; i++) {
        result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
      }
      var code = result.join('');

      postData.companyCode = code;
    }

    var newPostKey = firebase.database().ref().child('users').push().key;

    // Write the new post's data simultaneously in the posts list and the user's post list.
    var updates = {};
    updates['/users/' + newPostKey] = postData;
    firebase.database().ref().update(updates).then(() => {
      self.loading = false;
      //self.router.navigate(['/settings']); 
      //self.router.navigate(['/completeProfile']);
      if (!postData.isManager) {
        self.router.navigate(['/completeProfile']);
      } else {
        self.router.navigate(['/signup-step3']);
      }

      localStorage.setItem("oneTimeReload", "true");
      localStorage.setItem("displayName", self.registerForm.get("displayName").value);
      localStorage.setItem("currUserKey", newPostKey);
    });
  }


  signupwithGoogle() {

    var self = this;
    self.loading = true;
    firebase.auth().signInWithPopup(this.provider).then(function (result) {
      // This gives you a Google Access Token. You can use it to access the Google API.
      //var token = result.credential.accessToken;
      // The signed-in user info.
      var user = result.user;

      self.uid = user.uid;
      self.email = user.email;
      self.displayName = user.displayName;
      self.photoURL = user.photoURL;

      localStorage.setItem("uid", self.uid);

      self.checkAlreadyExists();
      //self.saveDatatoUserTable(user.uid, user.email,  user.displayName , user.photoURL);
    }).catch(function (error) {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      // The email of the user's account used.
      var email = error.email;
      // The firebase.auth.AuthCredential type that was used.
      var credential = error.credential;
      self.loading = false;


    });
  }

  checkAlreadyExists() {

    var self = this;

    firebase.database().ref().child('users')
      .once('value', function (snapshot) {
        var users = snapshot.val();
        for (var key in users) {
          var user = users[key];
          if (user.email == self.email) {
            self.alreadyExists = true;
            localStorage.setItem("currUserKey", key);
          }
        }

        if (self.alreadyExists) {
          localStorage.setItem("oneTimeReload", "true");
          // self.router.navigate(['/settings']);  
          self.router.navigate(['/completeProfile']);
        } else {
          self.saveDatatoUserTable();
        }
      });
  }

  saveDatatoUserTable() {

    var self = this;
    var postData: any = {
      uid: self.uid,
      email: self.email.toLowerCase(),
      displayName: self.displayName,
      photoURL: self.photoURL,
      timestamp: Number(new Date()),
    };

    if (this.dataService.role === "MANAGER") {
      postData.isManager = true;
    }

    var newPostKey = firebase.database().ref().child('users').push().key;

    // Write the new post's data simultaneously in the posts list and the user's post list.
    var updates = {};
    updates['/users/' + newPostKey] = postData;
    firebase.database().ref().update(updates).then(() => {
      self.loading = false;
      //self.router.navigate(['/settings']);   
      self.router.navigate(['/completeProfile']);
      localStorage.setItem("oneTimeReload", "true");
      localStorage.setItem("displayName", self.displayName);
      localStorage.setItem("currUserKey", newPostKey);
    });
  }

}
